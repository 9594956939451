import React from 'react'
import {Theme} from 'layout/theme'
import {WysiwygType} from 'types/basictypes'
import css from './error.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

type ErrorType = {
	description: WysiwygType
}

export const Error: React.FC<ErrorType> = (data) => {
	const {description} = data

	return (
		<div className={styles.error()}>
			<Theme.Container>
				<Theme.TrustedText>{description}</Theme.TrustedText>
			</Theme.Container>
		</div>
	)
}
