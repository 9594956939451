import {DPVideoblockBlock, DPWysiwyg} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import {Theme} from 'layout/theme'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import {Trusted} from 'util/trusted'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './videoblock.module.scss'

const styles = fromModule(css)

type VideoBlockType = Omit<DPVideoblockBlock, 'title'> & {
	caption?: DPWysiwyg
	title?: string
	overview?: boolean
}

export const VideoBlock: React.FC<VideoBlockType> = (data) => {
	const {_type, embed_code, size, caption, title, overview} = data
	const id = useIDFromAnchorBlock(data as DPVideoblockBlock)

	if (!embed_code) return null

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.videoblock.mod({overview})()}
		>
			<Theme.Container>
				<div className={styles.videoblock.content.mod(size?.name)()}>
					{title && (
						<Theme.H2
							className={styles.videoblock.content.title()}
							mod="blueline"
						>
							{title}
						</Theme.H2>
					)}
					<div className={styles.videoblock.iframecontainer()}>
						<CookieMissing object={embed_code}>
							<Trusted>{embed_code}</Trusted>
						</CookieMissing>
					</div>
					{caption && (
						<Theme.Wysiwyg className={styles.videoblock.caption()}>
							{caption}
						</Theme.Wysiwyg>
					)}
				</div>
			</Theme.Container>
		</Block>
	)
}
