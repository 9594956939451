import {ImageType, LinkType, WysiwygType} from 'types/basictypes'

import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {DPImageblockBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {Mod} from 'util/style'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './imageblock.module.scss'

const styles = fromModule(css)

export type ImageblockType = {
	title?: string
	image: ImageType
	caption?: WysiwygType
	link: LinkType
	size?: Mod<'small' | 'medium' | 'large' | 'fullwidth' | 'leaflet'>
}

export const Imageblock: React.FC<DPImageblockBlock> = (data) => {
	const {_type, title, image, caption, link, size} = data
	const id = useIDFromAnchorBlock(data)
	if (!image) return null

	return (
		<Block type={_type} anchorID={id} className={styles.imageblock()}>
			<Theme.Container>
				{title && (
					<div className={styles.imageblock.title()}>
						{title && <Theme.H2 mod={['blueline']}>{title}</Theme.H2>}
					</div>
				)}
				<div className={styles.imageblock.content.mod(size?.name)()}>
					<div className={styles.imageblock.image()}>
						{link?.url ? (
							<Link href={link.url}>
								<Image {...image} sizes={imageSizesSwitch(size?.name)} />
							</Link>
						) : (
							<Image {...image} sizes={imageSizesSwitch(size?.name)} />
						)}
					</div>
					{caption && (
						<div className={styles.imageblock.caption()}>
							<Theme.Wysiwyg>{caption}</Theme.Wysiwyg>
						</div>
					)}
				</div>
			</Theme.Container>
		</Block>
	)
}

function imageSizesSwitch(size) {
	switch (size) {
		case 'leaflet':
			return '285px'
		case 'small':
			return '320px'
		case 'medium':
			return '640px'
		case 'fullwidth':
			return '1580px'
		default:
			return '960px'
	}
}
