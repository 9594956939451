import {DPNode} from 'types/DPTypes'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import {Mod} from 'util/style'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './imagepreview.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Imagepreview: React.FC<
	DPNode & {mod?: Mod<'inherit' | 'readingroom'>; tabIndex?: number}
> = (data) => {
	const {_type, url, date, image, mod, tabIndex} = data
	let {title, description} = data

	if (data._type === 'article' || data._type === 'press') {
		title = data.alt_title ? data.alt_title : title
		description = data.alt_description ? data.alt_description : description
	}

	const {printDate} = useDpDateToPrettyDate()
	const t = useTranslation()
	const label = t.getNodeLabel(data)

	return (
		<div className={styles.imagepreview.mod(mod)()}>
			<Link
				href={url}
				tabIndex={tabIndex}
				className={styles.imagepreview.imgcontainer()}
			>
				{image?.src && <Image {...image} fill sizes="460px" />}
				<div
					className={styles.imagepreview.imgcontainer.overlay.mod(_type)()}
				/>
				{_type === 'video_page' && (
					<span className={styles.imagepreview.imgcontainer.play()}></span>
				)}
				{_type === 'podcast' && (
					<div className={styles.imagepreview.imgcontainer.volume()}>
						<Icon icon="VolumeHigh" mod={'maxwidth'} />
					</div>
				)}
				{_type && (
					<p className={styles.imagepreview.imgcontainer.type()}>
						{switchPagetypes(_type, t, label)}
					</p>
				)}
				{_type === 'press' && date && (
					<p className={styles.imagepreview.imgcontainer.date()}>
						{printDate(date)}
					</p>
				)}
			</Link>
			<div className={styles.imagepreview.content.mod(mod)()}>
				<Theme.H4 className={styles.imagepreview.content.title()} mod="light">
					<Link href={url} tabIndex={tabIndex}>
						<Theme.Wysiwyg>{title}</Theme.Wysiwyg>
					</Link>
				</Theme.H4>
				{description && (
					<Theme.Wysiwyg className={styles.imagepreview.content.description()}>
						{description}
					</Theme.Wysiwyg>
				)}
			</div>
		</div>
	)
}

function switchPagetypes(type: DPNode['_type'], t, label?: string) {
	switch (type) {
		case 'article':
			return label
		case 'press':
		case 'video_page':
		case 'podcast':
			return t.readingroom.types[type]
		default:
			return null
	}
}
