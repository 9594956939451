import {DPHeroBlock} from 'types/DPTypes'
import {Hero} from './hero'
import React from 'react'

/**
 * I created HeroBlock to solve circular dependency issues.
 * Never use this Component directly, use Hero instead.
 */
export const HeroBlock: React.FC<DPHeroBlock> = (data) => {
	return <Hero {...data} />
}
