'use client'

import {HTMLProps, forwardRef} from 'react'
import {
	NonSupportedAnchorBlockType,
	SupportedAnchorBlockType
} from 'util/useAnchor'

import {DPBlock} from 'types/DPTypes'
import {styler} from 'util/styler/Styler'

const styles = styler('block')

export type BlockProps =
	| {
			type: NonSupportedAnchorBlockType
			anchorID?: string | null
	  }
	| {
			type: SupportedAnchorBlockType
			anchorID: string | null
	  }

export type BlockFC<B extends DPBlock> = React.FC<Omit<B, 'id' | '_type'>>

export const Block: React.FC<BlockProps & HTMLProps<HTMLDivElement>> =
	forwardRef(({children, type, className, anchorID, ...props}, ref) => {
		const extraProps = anchorID ? {id: anchorID} : {}

		return (
			<div
				{...props}
				ref={ref}
				className={styles.with(type).with(className)()}
				{...extraProps}
			>
				{children}
			</div>
		)
	})
