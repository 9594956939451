import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import React from 'react'
import {DPLinksBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './links.module.scss'

const styles = fromModule(css)

export const Links: React.FC<DPLinksBlock> = (data) => {
	const {_type, text, links, cta_link} = data
	const id = useIDFromAnchorBlock(data)

	return (
		<Block type={_type} className={styles.links()} anchorID={id}>
			<Theme.Container>
				{text && (
					<div className={styles.links.text()}>
						<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
					</div>
				)}
				{links && links.length !== 0 && (
					<div className={styles.links.items()}>
						{links.map((link, i) => (
							<div key={i} className={styles.links.items.item()}>
								<div className={styles.links.items.item.link()}>
									<Link
										href={link.url}
										className={styles.links.items.item.link.label()}
									>
										{link.title}
									</Link>
								</div>
							</div>
						))}
					</div>
				)}
				{cta_link?.url && (
					<div className={styles.links.link()}>
						<Theme.Link href={cta_link.url}>
							<Theme.LinkText>{cta_link.title}</Theme.LinkText>
							<Theme.LinkIcon />
						</Theme.Link>
					</div>
				)}
			</Theme.Container>
		</Block>
	)
}
