import React from 'react'
import {Theme} from 'layout/theme'
import css from './cookiemissing.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useCookiehub} from 'util/useCookiehub'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const CookieMissing: React.FC<{
	object: string
	children: React.ReactNode
	category?: 'analytics' | 'marketing' | 'preferences'
}> = ({object, children, category}) => {
	const t = useTranslation()
	const cookiehub = useCookiehub()
	const cookieCategory = getCookieCategory(object, category)
	const allowedCookies =
		cookiehub?.hasAnswered() && cookiehub?.hasConsented(cookieCategory)

	if (allowedCookies || !cookieCategory) return <>{children}</>

	return (
		<div className={styles.cookiemissing()}>
			<div className={styles.cookiemissing.content()}>
				<Theme.Wysiwyg>
					{t.cookies.placeholder.text(cookieCategory)}
				</Theme.Wysiwyg>
				<Theme.Button
					as="button"
					onClick={() => cookiehub?.openSettings()}
					className={styles.cookiemissing.content.button()}
					mod="rounded"
				>
					{t.cookies.placeholder.button}
				</Theme.Button>
			</div>
		</div>
	)
}

export const getCookieCategory = (
	object: string,
	category: 'analytics' | 'marketing' | 'preferences'
) => {
	if (!object) return null
	if (category) return category

	switch (true) {
		case object.includes('cognitoforms.com'):
			return 'analytics'
		case object.includes('podbean.com'):
		case object.includes('vimeo.com'):
		case object.includes('youtube.com'):
			return 'marketing'
		default:
			return 'preferences'
	}
}
