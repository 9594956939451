import {useEffect, useState} from 'react'
import {DPLogosBlock, DPPopupTerm} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import dynamic from 'next/dynamic'
import {usePathname} from 'next/navigation'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './logos.module.scss'

const Dialog = dynamic(() => import('@material-ui/core/Dialog'), {ssr: false})

const styles = fromModule(css)

export const Logos: React.FC<DPLogosBlock> = (data) => {
	const {_type, title, logos, id: blockId} = data
	const blockIdString = String(blockId)
	const id = useIDFromAnchorBlock(data)
	const [clickedLogo, setClickedLogo] = useState<string>(null)
	const [popup, setPopup] = useState<string>(null)
	const [block, setBlock] = useState<string>(null)
	const pathname = usePathname()
	const openedPopup =
		logos.find(
			(logo) => blockIdString === block && getUniqueKey(logo.key) === popup
		) || null

	useEffect(() => {
		const params = new URL(window.location.toString()).searchParams
		setPopup(params.get('popup'))
		setBlock(params.get('block'))
	}, [])

	useEffect(() => {
		if (!clickedLogo) return
		const url = new URL(window.location.toString())
		url.searchParams.set('block', blockIdString)
		url.searchParams.set('popup', clickedLogo)
		window.history.replaceState({}, '', url.href)
		setPopup(clickedLogo)
		setBlock(blockIdString)
	}, [clickedLogo])

	useEffect(() => {
		if (!popup || clickedLogo) return
		if (blockIdString === block) scrollToPopup(`${blockId}_${popup}`)
	}, [popup, block])

	const handleClosePopup = () => {
		setPopup(null)
		setBlock(null)
		setClickedLogo(null)
		window.history.replaceState({}, '', pathname)
	}

	return (
		<>
			<Block type={_type} className={styles.logos()} anchorID={id}>
				<Theme.Container>
					{title && (
						<div className={styles.logos.title()}>
							<Theme.H2 mod={['blueline']}>{title}</Theme.H2>
						</div>
					)}
					<LogosGrid
						logos={logos}
						blockId={blockId}
						setClickedLogo={setClickedLogo}
					/>
				</Theme.Container>
			</Block>
			{openedPopup && (
				<LogosPopup item={openedPopup} handleClose={handleClosePopup} />
			)}
		</>
	)
}

type LogosGridType = {
	logos: Array<DPPopupTerm>
	blockId: number
	setClickedLogo: (popup: string) => void
}

const LogosGrid: React.FC<LogosGridType> = ({
	logos,
	blockId,
	setClickedLogo
}) => {
	if (!logos || logos.length === 0) return null

	return (
		<div className={styles.grid()}>
			<div className={styles.grid.items()}>
				{logos.map((item, i) => (
					<div className={styles.grid.items.item()} key={i}>
						<LogosGridItem
							item={item}
							blockId={blockId}
							setClickedLogo={setClickedLogo}
						/>
					</div>
				))}
			</div>
		</div>
	)
}

type LogosGridItemType = {
	item: DPPopupTerm
	blockId: number
	setClickedLogo: (popup: string) => void
}

const LogosGridItem: React.FC<LogosGridItemType> = ({
	item,
	blockId,
	setClickedLogo
}) => {
	const {name, logo} = item
	const itemKey = getUniqueKey(item.key)

	const handleClick = () => {
		setClickedLogo(itemKey)
	}

	return (
		<a
			onClick={handleClick}
			className={styles.imgcontainer()}
			id={`${blockId}_${itemKey}`}
		>
			{logo?.src ? (
				<Image {...logo} sizes="180px" style={{maxWidth: '180px'}} />
			) : (
				<Theme.H3>{name}</Theme.H3>
			)}
		</a>
	)
}

type LogosPopupType = {
	item: DPPopupTerm
	handleClose: () => void
}

const LogosPopup: React.FC<LogosPopupType> = ({item, handleClose}) => {
	const {name, title, logo, link, text} = item
	return (
		<Dialog
			open={true}
			onClose={handleClose}
			aria-hidden={false}
			className={styles.dialog()}
		>
			<div className={styles.dialog.content()}>
				{logo?.src ? (
					<div className={styles.dialog.content.imgcontainer()}>
						<Image {...logo} sizes="180px" />
					</div>
				) : (
					<Theme.H3>{name}</Theme.H3>
				)}
				<div className={styles.dialog.content.title()}>{title}</div>
				{link?.url && (
					<Link href={link.url} className={styles.dialog.content.link()}>
						<Icon icon="Globe" />
						{link.title || link.url}
					</Link>
				)}
				{text && (
					<Theme.Wysiwyg className={styles.dialog.content.text()}>
						{text}
					</Theme.Wysiwyg>
				)}
			</div>
		</Dialog>
	)
}

const getUniqueKey = (url: string) => {
	return url.slice(url.lastIndexOf('/') + 1)
}

const scrollToPopup = (to: string) => {
	if (!to) return

	const el: HTMLElement | null = document.getElementById(to)
	if (!el) return
	const y = el.getBoundingClientRect().top + window.scrollY - 150

	setTimeout(() => window.scrollTo({top: y, behavior: 'smooth'}), 0)
}
