import {FilterTag} from 'layout/search/filtertag'
import {Link} from 'util/link'
import React from 'react'
import css from './readingroomtags.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const ReadingroomTags: React.FC = () => {
	const {
		isDynamic,
		showClearBtn,
		type,
		label,
		partner_related,
		tags,
		toggleTag,
		setParams,
		updateParams
	} = useReadingroomSearchParams()
	const t = useTranslation()

	return (
		<div className={styles.readingroomtags()}>
			<div className={styles.readingroomtags.top()}>
				<div className={styles.readingroomtags.top.left()}>
					<div className={styles.readingroomtags.top.left.tags()}>
						{partner_related && (
							<FilterTag
								onClick={() =>
									updateParams({partner_related: !partner_related})
								}
							>
								{t.readingroom.related}
							</FilterTag>
						)}
						{label && (
							<FilterTag onClick={() => updateParams({label: null})}>
								{label}
							</FilterTag>
						)}
						{tags.map((tag) => (
							<FilterTag onClick={() => toggleTag(tag)} key={tag}>
								{tag}
							</FilterTag>
						))}
					</div>
					{isDynamic && showClearBtn && (
						<Link
							onClick={() => setParams({type})}
							className={styles.readingroomtags.top.left.clear()}
						>
							{t.readingroom.clear}
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}
