import {Loader} from 'layout/loader'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './searchresults.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const SearchresultsEmpty: React.FC = () => {
	const {query} = useReadingroomSearchParams()
	const t = useTranslation()

	return (
		<div className={styles.empty()}>
			<Theme.Container>
				<Theme.H3 className={styles.empty.title()}>
					{t.readingroom.noResultsTitle}
				</Theme.H3>
				<p className={styles.empty.text()}>
					{t.readingroom.noResultsPart1} <b>{query}</b>
					{t.readingroom.noResultsPart2}
				</p>
			</Theme.Container>
		</div>
	)
}

export const SearchresultsLoader: React.FC = () => {
	return (
		<div className={styles.loader()}>
			<Loader />
		</div>
	)
}

export const SearchresultsMore: React.FC<{
	onClick: () => void
	children?: React.ReactNode
}> = ({onClick, children}) => {
	const t = useTranslation()

	return (
		<div className={styles.more()}>
			<button onClick={onClick} className={styles.more.button()}>
				{children || t.readingroom.readmore}
			</button>
		</div>
	)
}
