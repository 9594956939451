import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import React from 'react'
import {DPBlocks2Block} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './blocks2.module.scss'

const styles = fromModule(css)

export const Blocks2: React.FC<DPBlocks2Block> = (data) => {
	const {_type, block_left, block_right} = data
	const id = useIDFromAnchorBlock(data)

	return (
		<Block type={_type} className={styles.blocks2()} anchorID={id}>
			<Theme.Container>
				<div className={styles.blocks2.row()}>
					<div className={styles.blocks2.left()}>
						{block_left && <Theme.Wysiwyg>{block_left}</Theme.Wysiwyg>}
					</div>
					<div className={styles.blocks2.right()}>
						{block_right && <Theme.Wysiwyg>{block_right}</Theme.Wysiwyg>}
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}
