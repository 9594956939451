import React, {HTMLProps} from 'react'

import css from './mousescroll.module.scss'
import {fromModule} from 'util/styler/Styler'
import {scrollToHash} from 'util/link'

const styles = fromModule(css)

export const Mousescroll: React.FC<
	HTMLProps<HTMLAnchorElement> & {
		id: string
	}
> = ({id, ...props}) => (
	<span
		title={`Scroll to ${!id.startsWith('#scroll') ? id : 'content'}`}
		className={styles.mousescroll.mergeProps(props)()}
		onClick={() => scrollToHash(id, -25)}
	/>
)
