export const getRelativeDate = (date: Date) => {
	if (!date) return null

	const today = new Date()
	const difference = (today.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)

	switch (true) {
		case difference < 7:
			return 'Just now'
		case difference < 14:
			return 'About a week ago'
		default:
			return 'More than two weeks ago'
	}
}
