import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import React, {useState} from 'react'
import {DPTestimonialsBlock, DPTestimonialsItemBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {BgFixed} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import {useInterval} from 'util/useinterval'
import css from './testimonials.module.scss'

const styles = fromModule(css)

export const Testimonials: React.FC<DPTestimonialsBlock> = (data) => {
	const {_type, items} = data
	const carousel = useCarousel()
	const [isTouched, setIsTouched] = useState(false)
	const id = useIDFromAnchorBlock(data)

	useInterval(() => {
		if (isTouched) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 7500)

	if (!items || items.length === 0) return null

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.testimonials()}
			onTouchStart={() => setIsTouched(true)}
			onMouseDown={() => setIsTouched(true)}
		>
			{items.map((testimonial, i) => (
				<BgFixed
					key={i}
					image={testimonial.testimonials_bg_image}
					className={styles.testimonials.bg.is({
						active: carousel.isActive(i)
					})()}
				/>
			))}
			<Theme.Container>
				<div className={styles.testimonials.block()}>
					{carousel?.total! > 1 && (
						<div className={styles.testimonials.block.previous()}>
							<CarouselControlsPrevious carousel={carousel} />
						</div>
					)}
					<Carousel {...carousel}>
						{items.map((testimonial, i) => (
							<div
								key={i}
								className={styles.testimonials.block.content.is({
									active: carousel.isActive(i)
								})()}
							>
								<TestimonialsItem {...testimonial} />
							</div>
						))}
					</Carousel>
					{carousel?.total! > 1 && (
						<div className={styles.testimonials.block.next()}>
							<CarouselControlsNext carousel={carousel} />
						</div>
					)}
					<CarouselNav
						carousel={carousel}
						className={styles.testimonials.block.nav()}
					/>
				</div>
			</Theme.Container>
		</Block>
	)
}

const TestimonialsItem: React.FC<DPTestimonialsItemBlock> = ({
	testimonials_avatar_image,
	testimonials_name: name,
	testimonials_quote: quote,
	testimonials_link: link
}) => {
	return (
		<div className={styles.item()}>
			{(testimonials_avatar_image?.src || name) && (
				<div className={styles.item.author()}>
					{testimonials_avatar_image?.src && (
						<div className={styles.item.author.image()}>
							<Image {...testimonials_avatar_image} fill sizes="36px" />
						</div>
					)}
					{name && <p className={styles.item.author.label()}>{name}</p>}
				</div>
			)}
			{quote && (
				<div className={styles.item.quote()} title={quote}>
					<Theme.TrustedText>{quote}</Theme.TrustedText>
				</div>
			)}
			{link?.url && (
				<div className={styles.item.link()}>
					<Theme.Link href={link.url} mod="inherit">
						<Theme.LinkText>{link.title}</Theme.LinkText>
						<Theme.LinkIcon />
					</Theme.Link>
				</div>
			)}
		</div>
	)
}
