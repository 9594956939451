import {Carousel, useCarousel} from '@codeurs/carousel'
import {
	CarouselControlsNext,
	CarouselControlsPrevious
} from 'layout/carousel/carouselcontrols'
import {DPCasesBlock, DPCasesItemBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {BgFixed} from 'layout/partials/bg'
import {Theme} from 'layout/theme'
import React from 'react'
import {CarouselType} from 'types/basictypes'
import {useTranslation} from 'util/i18'
import {useMediaQuery} from 'util/mediaquery'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import {useInterval} from 'util/useinterval'
import css from './cases.module.scss'

const styles = fromModule(css)

export const Cases: React.FC<DPCasesBlock & {firstBlock?: boolean}> = (
	data
) => {
	const {_type, items, content, content_visible, firstBlock} = data
	const id = useIDFromAnchorBlock(data)
	const carousel = useCarousel()
	const ipadPort = useMediaQuery('(min-width: 768px)')
	const active = carousel.current

	useInterval(() => {
		if (!ipadPort) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 10000)

	if (!items || items.length === 0) return null
	const sliceLength = content ? 3 : items.length

	return (
		<Block type={_type} anchorID={id} className={styles.cases()}>
			{items.slice(0, sliceLength).map((item, i) => (
				<BgFixed
					image={item.cases_background_image}
					priority={firstBlock && i === 0}
					sizes="(max-width: 480x) 375px, (max-width: 1600px) 100vw, (max-width: 1720px) 1480px, 1580px"
					className={styles.cases.bg.is({active: i === active})()}
					mod="cases"
					key={i}
				/>
			))}
			<div className={styles.cases.row()}>
				<div className={styles.cases.row.carousel()}>
					<Theme.Container>
						<div className={styles.cases.carousel()}>
							<CarouselControlsPrevious
								carousel={carousel}
								className={styles.cases.carousel.prev()}
							/>
							<Carousel
								{...carousel}
								full
								className={styles.cases.carousel.slides()}
							>
								{items.slice(0, sliceLength).map((item, i) => (
									<div
										key={i}
										className={styles.cases.carousel.slide.is({
											active: i === active
										})()}
									>
										<CasesItem {...item} />
									</div>
								))}
							</Carousel>
							<CarouselControlsNext
								carousel={carousel}
								className={styles.cases.carousel.next()}
							/>
						</div>
						<CasesCarouselnav
							carousel={carousel}
							items={items.slice(0, sliceLength)}
						/>
					</Theme.Container>
				</div>
				{content && content_visible && (
					<div className={styles.cases.content()}>
						<Theme.Wysiwyg mod="inherit">{content}</Theme.Wysiwyg>
					</div>
				)}
			</div>
		</Block>
	)
}

const CasesCarouselnav: React.FC<{
	carousel: CarouselType
	items?: DPCasesItemBlock[]
}> = ({carousel, items}) => {
	const t = useTranslation()
	const active = carousel.current
	if (items?.length < 2) return null

	return (
		<div className={styles.carouselnav()}>
			{items.map((item, i) => (
				<div
					className={styles.carouselnav.item.is({
						active: i === active
					})()}
					key={i}
				>
					<button
						tabIndex={0}
						onClick={() => carousel.goTo(i)}
						aria-label={t.carousel.index_arialabel}
					>
						<span className={styles.carouselnav.item.line()} />
						<span className={styles.carouselnav.item.title()}>
							{item.title}
						</span>
					</button>
				</div>
			))}
		</div>
	)
}

const CasesItem: React.FC<DPCasesItemBlock> = ({title, description, link}) => {
	return (
		<div className={styles.item()}>
			{title && (
				<div className={styles.item.title()}>
					<Theme.H2>{title}</Theme.H2>
				</div>
			)}
			{description && (
				<p className={styles.item.description()}>{description}</p>
			)}
			{link?.url && (
				<Theme.Button
					href={link.url}
					className={styles.item.link()}
					mod="rounded"
				>
					<Theme.ButtonText>{link.title}</Theme.ButtonText>
					<Theme.ButtonIcon />
				</Theme.Button>
			)}
		</div>
	)
}
