import {usePathname, useRouter, useSearchParams} from 'next/navigation'
import React, {useState} from 'react'
import {
	DPHierarchy,
	DPParentTerm,
	DPProductCameraTerm,
	DPProductsBlock
} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {FormSimpleCheckbox} from 'layout/form/formsimplecheckbox'
import {Cardpreview} from 'layout/preview/cardpreview'
import {SearchresultsLoader} from 'layout/search/searchresults'
import {Theme} from 'layout/theme'
import AnimateHeight from 'react-animate-height'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import {useSearchParamsAreLoaded} from 'util/useSearchParamsAreLoaded'
import css from './products.module.scss'

const styles = fromModule(css)

type FilterTerm = DPProductCameraTerm

const useProductTerms = () => {
	const searchParams = useSearchParams()
	const terms = searchParams.getAll('terms')

	if (!terms) return []
	if (Array.isArray(terms)) return terms.map((t) => parseInt(t, 10))
	return [parseInt(terms, 10)]
}

export const Products: React.FC<DPProductsBlock> = (data) => {
	const loaded = useSearchParamsAreLoaded()

	if (!loaded) return <SearchresultsLoader />
	return <ProductsView {...data} />
}

const ProductsView: React.FC<DPProductsBlock> = (data) => {
	const {_type, items, filters} = data
	const terms = new Set(useProductTerms())
	const id = useIDFromAnchorBlock(data)
	const activeFilters = filters.filter((filter) => {
		return filter.children.find((child) => terms.has(child.id))
	})
	const visibleItems = items.filter((product) => {
		if (!activeFilters.length) return true
		const productValues = new Set(
			(product.type?.product_filters || []).map((value) => value.id)
		)
		const invalidFilter = activeFilters.find((filter) => {
			const acceptableValues = filter.children.filter((child) =>
				terms.has(child.id)
			)
			return !acceptableValues.find((value) => productValues.has(value.id))
		})
		return invalidFilter ? false : true
	})

	return (
		<Block type={_type} className={styles.products()} anchorID={id}>
			<Theme.Container>
				<div className={styles.products.row()}>
					<div className={styles.products.filters()}>
						<ProductsFilters filters={filters} />
					</div>
					<div className={styles.products.content()}>
						<div className={styles.products.content.items()}>
							{visibleItems.map((item) => (
								<div
									className={styles.products.content.items.item()}
									key={item.url}
								>
									<Cardpreview
										url={item.url}
										image={item.image}
										text={`<h5>${item.title}</h5 > `}
										text_mod={['small', 'full']}
										objectFit="contain"
										display_fallback={false}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}

const ProductsFilters: React.FC<{
	filters: DPHierarchy<FilterTerm>
}> = ({filters}) => {
	return (
		<div className={styles.filters()}>
			{filters.map((filter) => (
				<ProductsFiltersCategory key={filter.key} filter={filter} />
			))}
		</div>
	)
}

const ProductsFiltersCategory: React.FC<{filter: DPParentTerm<FilterTerm>}> = ({
	filter
}) => {
	const [isOpen, setOpen] = useState(true)

	return (
		<div className={styles.filterscategory()}>
			<Theme.H5
				className={styles.filterscategory.title.is({open: isOpen})()}
				mod="toggle"
			>
				<span onClick={() => setOpen(!isOpen)}>{filter.name}</span>
			</Theme.H5>
			<AnimateHeight height={isOpen ? 'auto' : 0}>
				<div className={styles.filterscategory.items()}>
					{filter.children.map((term) => (
						<div className={styles.filterscategory.items.item()} key={term.id}>
							<ProductsFiltersSubcategory term={term} />
						</div>
					))}
				</div>
			</AnimateHeight>
		</div>
	)
}

const ProductsFiltersSubcategory: React.FC<{
	term: DPParentTerm<FilterTerm>
}> = ({term}) => {
	const terms = useProductTerms()
	const router = useRouter()
	const pathname = usePathname()
	const checked = !!terms.find((t) => t === term.id)

	return (
		<>
			<div className={styles.filterssubcategory()}>
				<FormSimpleCheckbox
					name={'p' + term.id}
					label={term.name}
					value={checked}
					onChange={() => {
						const newTerms = new Set(terms).has(term.id)
							? terms.filter((t) => t !== term.id)
							: [...terms, term.id]
						const queryTerms = newTerms
							.reduce((prev, next) => {
								return prev + 'terms=' + next.toString() + '&'
							}, '?')
							.slice(0, -1)
						router.push(pathname + queryTerms, {scroll: false})
					}}
				/>
			</div>
		</>
	)
}
