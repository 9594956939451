import { useApp } from "AppContext"
import { format as fnsFormat, parse } from "date-fns"
import { DPDate } from "types/DPTypes"
import { nl, enUS } from 'date-fns/locale'


export const dpDateToJsDate = (dateStr: DPDate) => {
	if (!dateStr) return null
	return parse(dateStr, 'yyyy-MM-dd HH:mm:ss', Date.now())
}

export const useDpDateToPrettyDate = () => {
	const { lang } = useApp()
	let locale = enUS
	if (lang === 'nl') locale = nl

	return {
		printDate: (dateStr: DPDate, format = 'd MMMM yyyy') => {
			const date = dpDateToJsDate(dateStr)
			if (!date) return null
			return fnsFormat(date, format, { locale })
		}
	}
}

export function jsDateToDrupalDate(date: Date) {
	return fnsFormat(date, 'YYYY-MM-DD HH-mm-ss')
}