import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as ReactDOMClient from 'react-dom/client'

import {Block} from 'blocks/block'
import {DPTellitBlock} from 'types/DPTypes'
import css from './tellit.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useEffect} from 'react'

const styles = fromModule(css)

interface Tellit {
	createFirebasePlayer(embedKey: string, selector: string): void
}

let tellit: Promise<Tellit> | undefined

function loadTellit(): Promise<Tellit> {
	window.React = React
	window.ReactDOM = {...ReactDOM, ...ReactDOMClient}
	const script = document.createElement('script')
	const loaded = new Promise((resolve, reject) => {
		script.onload = resolve
		script.onerror = reject
	})
	script.src = 'https://tellit-imec.netlify.app/tellit.min.js'
	script.async = true
	document.head.appendChild(script)
	return loaded.then(() => window['tellit'])
}

function useTellit(embedKey: string, id: string) {
	useEffect(() => {
		let canceled = false
		tellit ??= loadTellit()
		tellit
			.then(({createFirebasePlayer}) => {
				if (!canceled) createFirebasePlayer(embedKey, `#${id}`)
			})
			.catch(console.error)
		return () => {
			canceled = true
			// Workaround to clean svg set by tellit script so header/footer isn't left with a giant empty space
			document.body.childNodes.forEach((element) => {
				if ((element as any).tagName === 'svg') {
					document.body.removeChild(element)
				}
			})
		}
	}, [embedKey, id])
}

export const Tellit: React.FC<DPTellitBlock> = (data) => {
	const id = `tellit_${data.id}`
	useTellit(data.embed_key, id)
	return <Block type="tellit" className={styles.tellit()} anchorID={id}></Block>
}
