import React, {useEffect, useState} from 'react'
import {DPOfficesBlock, DPOfficesItemBlock} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import AnimateHeight from 'react-animate-height'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './offices.module.scss'

const styles = fromModule(css)

export const Offices: React.FC<DPOfficesBlock> = (data) => {
	const {_type, items} = data
	const [isDesktop, setIsDesktop] = useState<any>(true)
	const id = useIDFromAnchorBlock(data)
	useEffect(() => {
		setIsDesktop(window.innerWidth >= 1024 ? true : false)
	}, [])

	if (!items || items.length === 0) return null

	if (items && items.length >= 2) {
		const firstText = items[0].text?.length
		const secondText = items[1].text?.length
		if (firstText / 2 > secondText) {
			return (
				<Block type={_type} className={styles.offices()} anchorID={id}>
					<div className={styles.offices.row()}>
						<div className={styles.offices.left()}>
							<OfficesItem
								mod="full"
								isDesktop={isDesktop}
								defaultVisible={false}
								{...items[0]}
							/>
						</div>
						<div className={styles.offices.right()}>
							{items.slice(1, 5).map((office, i) => (
								<div key={i} className={styles.offices.right.item()}>
									<OfficesItem
										key={i}
										{...office}
										isDesktop={isDesktop}
										defaultVisible={false}
									/>
								</div>
							))}
						</div>
					</div>
					<OfficesItems
						items={items.slice(5, items.length)}
						isDesktop={isDesktop}
					/>
				</Block>
			)
		}
	}

	return (
		<Block type={_type} className={styles.offices()} anchorID={id}>
			<OfficesItems items={items} isDesktop={isDesktop} />
		</Block>
	)
}

const OfficesItems: React.FC<{
	items: Array<DPOfficesItemBlock>
	isDesktop?: boolean
}> = ({items, isDesktop}) => {
	if (!items || items.length === 0) return null

	return (
		<div className={styles.items()}>
			{items.map((office, i) => (
				<div key={i} className={styles.items.item()}>
					<OfficesItem
						{...office}
						isDesktop={isDesktop}
						defaultVisible={false}
					/>
				</div>
			))}
		</div>
	)
}

const OfficesItem: React.FC<
	DPOfficesItemBlock & {
		mod?: 'full'
		isDesktop?: boolean
		defaultVisible?: boolean
	}
> = (data) => {
	const {title, text, mod, defaultVisible, isDesktop} = data
	const [isVisible, setVisible] = useState(defaultVisible)

	return (
		<div className={styles.item.mod(mod)()}>
			<a className={styles.item.top()} onClick={() => setVisible(!isVisible)}>
				<Theme.H5 mod="blueline">{title}</Theme.H5>
				{!isDesktop && (
					<div className={styles.item.top.toggler()}>
						{isVisible ? <Icon icon="Minus" /> : <Icon icon="Plus" />}
					</div>
				)}
			</a>
			<AnimateHeight height={isDesktop || isVisible ? 'auto' : 0}>
				<div className={styles.item.content()}>
					{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
				</div>
			</AnimateHeight>
		</div>
	)
}
