'use client'

import {usePathname, useSearchParams} from 'next/navigation'

import {DPTagTerm} from 'types/DPTypes'
import {PING_TAG} from 'util/urls'
import unfetch from 'unfetch'

export type SearchParams = {
	terms: number[]
	tags: string[]
	filters: string[]
	page: number
	type: string
	label: string
	query: string
	partner_related: boolean
}

export const useImecSearchParams = () => {
	const sp = useSearchParams()
	const pathname = usePathname()
	const result: SearchParams = {
		page: parseInt(parseString(sp.get('page'))) || 0,
		terms: parseArray([...sp.getAll('terms'), ...sp.getAll('terms[]')]).map(
			(val) => parseInt(val, 10)
		),
		tags: parseArray([...sp.getAll('tags'), ...sp.getAll('tags[]')]),
		filters: parseArray([...sp.getAll('filters'), ...sp.getAll('filters[]')]),
		type: parseString(sp.get('type')),
		label: parseString(sp.get('label')),
		query: parseString(sp.get('query')),
		partner_related: parseBoolean(sp.get('partner_related'))
	}
	const termsSet = new Set(result.terms)
	const tagsSet = new Set(result.tags)

	const createUrlObject = (params: Partial<SearchParams>) => {
		const qryParams: {[k: string]: any} = {}
		if (sp.get('lang')) qryParams.lang = sp.get('lang')
		if (sp.get('slug')) qryParams.slug = sp.get('slug')
		const query = {...qryParams, ...cleanup(params)}

		return {
			pathname: pathname,
			query
		}
	}
	const updateParams = (newParams: Partial<SearchParams>) => {
		const urlObj = createUrlObject({...result, ...newParams})
		const params = new URLSearchParams()
		Object.keys(urlObj.query).forEach((key) => {
			const value = urlObj.query[key]
			if (Array.isArray(value)) {
				value.forEach((val) => params.append(key, val))
			} else {
				params.set(key, value)
			}
		})
		window.history.pushState(null, '', `?${params.toString()}`)
	}
	const setParams = (newParams: Partial<SearchParams>) => {
		const urlObj = createUrlObject(newParams)
		const params = new URLSearchParams()
		Object.keys(urlObj.query).forEach((key) => {
			const value = urlObj.query[key]
			if (Array.isArray(value)) {
				value.forEach((val) => params.append(key, val))
			} else {
				params.set(key, value)
			}
		})
		window.history.pushState(null, '', `?${params.toString()}`)
	}

	return {
		...result,
		termsSet,
		tagsSet,
		toggleTerm: (id) => {
			if (!id) return
			if (termsSet.has(id)) {
				updateParams({
					page: 0,
					terms: result.terms.filter((term) => term !== id)
				})
			} else {
				updateParams({page: 0, terms: [...result.terms, id]})
			}
		},
		toggleTag: (tag: DPTagTerm | string) => {
			if (!tag) return
			const tagName = typeof tag === 'string' ? tag : tag.name

			if (tagsSet.has(tagName)) {
				updateParams({page: 0, tags: result.tags.filter((t) => t !== tagName)})
			} else {
				updateParams({page: 0, tags: [...result.tags, tagName]})

				if (typeof tag !== 'string') {
					const pingUrl = PING_TAG(tag.id)
					if (pingUrl) unfetch(pingUrl)
				}
			}
		},
		createUrlObject,
		updateParams,
		setParams
	}
}

const parseArray = (value: string | string[] | undefined | null): string[] => {
	if (!value) return []
	if (Array.isArray(value)) return value
	return [value]
}

const parseString = (value: string | string[] | undefined | null): string => {
	if (!value) return undefined
	if (Array.isArray(value)) return value[0]
	return value
}

const parseBoolean = (value: string | string[] | undefined | null): boolean => {
	if (!value) return false
	return true
}

const cleanup = (params: Record<string, any>) => {
	const result = {}

	Object.keys(params).forEach((key) => {
		const value = params[key]
		if (!value) return

		if (Array.isArray(params[key])) {
			if (value.length === 0) return
			result[key + '[]'] = value
		} else {
			result[key] = value
		}
	})

	return result
}
