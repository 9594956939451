import {DPParentTerm, DPTagTerm} from 'types/DPTypes'
import React, {useState} from 'react'
import {
	SearchbarInput,
	SearchbarMore,
	SearchbarToggler
} from 'layout/search/searchbar'
import {
	Subjects,
	SubjectsColumn,
	SubjectsTag,
	SubjectsTitle
} from 'layout/search/subjects'

import {ReadingroomTags} from 'blocks/readingroom/components/readingroomtags'
import {Theme} from 'layout/theme'
import css from './readingroomsearch.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useReadingroomSearchParams} from 'blocks/readingroom/readingroom'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const ReadingroomSearch: React.FC<{
	categories: DPParentTerm<DPTagTerm>[]
	popularTags: DPTagTerm[]
	useSuggestions?: boolean
}> = ({categories, popularTags, useSuggestions}) => {
	const [open, setOpen] = useState(false)
	const t = useTranslation()
	const {query, updateParams, toggleTag} = useReadingroomSearchParams()

	const hasToggler = categories.length > 0

	return (
		<div className={styles.readingroomsearch()}>
			<Theme.Container>
				<div className={styles.readingroomsearch.flex()}>
					<div className={styles.readingroomsearch.flex.input()}>
						<SearchbarInput
							onSearch={(query) => updateParams({query})}
							useSuggestions={useSuggestions}
							query={query}
							mod="dark"
						/>
					</div>
					{hasToggler && (
						<div className={styles.readingroomsearch.flex.toggle()}>
							<SearchbarToggler open={open} onToggle={() => setOpen(!open)}>
								{open ? t.readingroom.showless : t.readingroom.showmore}
							</SearchbarToggler>
						</div>
					)}
				</div>
				<ReadingroomTags />
				{hasToggler && (
					<SearchbarMore open={open}>
						<ReadingroomsearchSubjects categories={categories} />
					</SearchbarMore>
				)}
			</Theme.Container>
		</div>
	)
}

const ReadingroomsearchSubjects: React.FC<{
	categories: DPParentTerm<DPTagTerm>[]
}> = ({categories}) => {
	const {type, label, updateParams, partner_related, toggleTag} =
		useReadingroomSearchParams()
	const t = useTranslation()
	const extraCategory = t.readingroom.extra[process.env.NEXT_PUBLIC_PROJECT_KEY]

	return (
		<Subjects>
			{categories.map((tag) => (
				<SubjectsColumn key={tag.id}>
					<SubjectsTitle onClick={() => toggleTag(tag)}>
						{tag.name}
					</SubjectsTitle>
					{tag.children.map((child) => (
						<SubjectsTag onClick={() => toggleTag(child)} key={child.id}>
							{child.name}
						</SubjectsTag>
					))}
				</SubjectsColumn>
			))}
			{extraCategory && (
				<SubjectsColumn>
					<SubjectsTitle>{extraCategory.name}</SubjectsTitle>
					{extraCategory.items.map((item, index) => {
						switch (item.type) {
							case 'partner_related':
								return (
									<SubjectsTag
										onClick={() =>
											updateParams({partner_related: !partner_related})
										}
										key={index}
									>
										{item.name}
									</SubjectsTag>
								)
							case 'tag':
								return (
									<SubjectsTag onClick={() => toggleTag(item.name)} key={index}>
										{item.name}
									</SubjectsTag>
								)
							case 'label':
								return (
									<SubjectsTag
										onClick={() => {
											updateParams({
												label: label === item.name ? null : item.name
											})
										}}
										key={index}
									>
										{item.name}
									</SubjectsTag>
								)
							case 'pagetype':
								return (
									<SubjectsTag
										onClick={() =>
											updateParams({
												type: type === item.pagetype ? null : item.pagetype
											})
										}
										key={index}
									>
										{item.name}
									</SubjectsTag>
								)
							default:
								return null
						}
					})}
				</SubjectsColumn>
			)}
		</Subjects>
	)
}
