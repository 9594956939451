import {
	DPArticle,
	DPDossier,
	DPPodcast,
	DPPress,
	DPVideoPage
} from 'types/DPTypes'

import {Imagepreview} from 'layout/preview/imagepreview'
import {PreviewType} from 'layout/preview/previewtype'
import {Theme} from 'layout/theme'
import React from 'react'
import {useDpDateToPrettyDate} from 'util/dates'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './magazine.module.scss'

const styles = fromModule(css)

export const Magazine: React.FC<{children: React.ReactNode}> = ({children}) => {
	return <div className={styles.magazine()}>{children}</div>
}

export const MagazineItems: React.FC<{
	items: Array<DPArticle | DPPress | DPVideoPage | DPDossier | DPPodcast>
}> = (data) => {
	const {items} = data

	return (
		<div className={styles.items()}>
			<Theme.Container>
				<div className={styles.items.flex()}>
					{items.map((item, i) => (
						<div
							className={styles.items.flex.item()}
							key={`${i}_${item.node_id}`}
						>
							<Imagepreview {...item} mod={'readingroom'} />
						</div>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

export const MagazineTextualItems: React.FC<{
	items: Array<PreviewType>
}> = (data) => {
	const {items} = data
	const {printDate} = useDpDateToPrettyDate()

	return (
		<div className={styles.textual.container()}>
			{items.map((result, i) => (
				<Link key={i} href={result.url} className={styles.textual()}>
					<Theme.Container>
						<p className={styles.textual.date()}>{printDate(result.date)}</p>
						<div className={styles.textual.title()}>
							<Theme.H4>
								<Theme.Wysiwyg>
									{result.alt_title ? result.alt_title : result.title}
								</Theme.Wysiwyg>
							</Theme.H4>
						</div>
						{result.description && (
							<div className={styles.textual.description()}>
								<Theme.Wysiwyg>
									{result.alt_description
										? result.alt_description
										: result.description}
								</Theme.Wysiwyg>
							</div>
						)}
					</Theme.Container>
				</Link>
			))}
		</div>
	)
}
