import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import {usePathname} from 'next/navigation'
import React from 'react'
import {DPContactBlock} from 'types/DPTypes'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './contact.module.scss'

const styles = fromModule(css)

export const Contact: React.FC<DPContactBlock> = (data) => {
	const {_type, text, avatar_image, link, id: paragraphID} = data
	const {contact} = useLinks()
	const id = useIDFromAnchorBlock(data)
	const pathname = usePathname()

	let contactUrl = link?.url || contact.url
	if (contactUrl.indexOf('?') === -1) {
		const originalUrl = encodeURIComponent(pathname)
		contactUrl = `${contactUrl}?origin_url=${originalUrl}&contactblock=${paragraphID}#contact`
	}

	return (
		<Block type={_type} anchorID={id} className={styles.contact()}>
			<Theme.Container>
				<div className={styles.contact.row()}>
					<div className={styles.contact.row.col()}>
						<div className={styles.contact.content()}>
							{avatar_image?.src && (
								<div className={styles.contact.content.image()}>
									<Image
										{...avatar_image}
										sizes="(max-width: 600px) 100vw, 460px"
									/>
								</div>
							)}
							{text && (
								<div className={styles.contact.content.text()}>
									<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
								</div>
							)}
						</div>
					</div>
					<div className={styles.contact.row.col()}>
						<Link href={contactUrl} className={styles.contact.cta()}>
							<p className={styles.contact.cta.text()}>
								{(link && link.title) || contact.title}
							</p>
							<div className={styles.contact.cta.circle()}>
								<span className={styles.contact.cta.circle.icon()}>
									<Icon icon="ArrowRight" />
								</span>
							</div>
						</Link>
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}
