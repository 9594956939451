import React, {useState} from 'react'
import {DPFaqBlock, DPFaqItemBlock} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import AnimateHeight from 'react-animate-height'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './faq.module.scss'

const styles = fromModule(css)

export const Faq: React.FC<DPFaqBlock> = (data) => {
	const {_type, title, items} = data
	const id = useIDFromAnchorBlock(data)
	if (items?.length === 0) return null

	return (
		<Block type={_type} className={styles.faq()} anchorID={id}>
			<Theme.Container>
				{title && (
					<Theme.H2 className={styles.faq.title()} mod={['blueline']}>
						{title}
					</Theme.H2>
				)}
				<div className={styles.faq.items()}>
					{items.map((item, i) => (
						<FaqItem {...item} key={i} />
					))}
				</div>
			</Theme.Container>
		</Block>
	)
}

const FaqItem: React.FC<DPFaqItemBlock> = ({label, description}) => {
	const [isOpen, setOpen] = useState(false)
	if (!label) return null
	label = label.replace('<p>', '<h3>').replace('</p>', '</h3>')

	return (
		<div className={styles.item()}>
			<a onClick={() => setOpen(!isOpen)} className={styles.item.question()}>
				<div className={styles.item.question.text()}>
					<Theme.Wysiwyg>{label}</Theme.Wysiwyg>
				</div>
				<div className={styles.item.question.icon.is({open: isOpen})()}>
					<Icon icon="ChevronRight" />
				</div>
			</a>
			{description && (
				<AnimateHeight height={isOpen ? 'auto' : 0}>
					<div className={styles.item.answer()}>
						<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
					</div>
				</AnimateHeight>
			)}
		</div>
	)
}
