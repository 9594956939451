import {
	DPArticle,
	DPDossier,
	DPNode,
	DPPodcast,
	DPPress,
	DPVideoPage
} from 'types/DPTypes'

import {BgFixed} from 'layout/partials/bg'
import {CookieMissing} from 'layout/cookies/cookiemissing'
import {Icon} from 'assets/icon'
import {Imagepreview} from 'layout/preview/imagepreview'
import {Link} from 'util/link'
import React from 'react'
import {Theme} from 'layout/theme'
import {Trusted} from 'util/trusted'
import css from './highlighted.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useDpDateToPrettyDate} from 'util/dates'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const Highlighted: React.FC<{
	dossier: DPDossier
	mod?: 'blue'
}> = ({dossier, mod}) => {
	if (!dossier) return null
	const {url, title, articles} = dossier
	if (articles && articles?.length === 0) return null

	return (
		<div className={styles.highlighted.mod(mod)()}>
			<Theme.Container>
				<Theme.H1
					as="h2"
					className={styles.highlighted.title()}
					mod={['arrow', 'white']}
				>
					<Link href={url}>{`Dossier: ${title}`}</Link>
				</Theme.H1>
				<div className={styles.highlighted.first()}>
					<div className={styles.highlighted.first.row()}>
						<div className={styles.highlighted.first.row.media()}>
							{articles[0]?._type === 'video_page' ? (
								<HighlightedVideo {...articles[0]} />
							) : (
								<HighlightedImage {...articles[0]} />
							)}
						</div>
						<div className={styles.highlighted.first.row.content()}>
							<HighlightedContent highlighted={articles[0]} />
						</div>
					</div>
				</div>
				<div className={styles.highlighted.items()}>
					{articles.slice(1, articles.length).map((item, i) => (
						<div
							className={styles.highlighted.items.item()}
							key={`${i}_${item.node_id}`}
						>
							<Imagepreview {...item} mod="inherit" />
						</div>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

export const HighlightedImage: React.FC<DPArticle | DPPress | DPPodcast> = ({
	url,
	background_image,
	_type
}) => {
	const t = useTranslation()

	return (
		<Link href={url} className={styles.image()}>
			<BgFixed
				image={background_image}
				hideOverlay={true}
				className={styles.image.bg()}
			/>
			{_type === 'podcast' && (
				<div className={styles.image.volume.container()}>
					<div className={styles.image.volume()}>
						<Icon icon="VolumeHigh" mod={'maxwidth'} />
					</div>
				</div>
			)}
		</Link>
	)
}

export const HighlightedVideo: React.FC<DPVideoPage> = ({embed_code}) => {
	if (!embed_code) return null
	return (
		<div className={styles.media()}>
			<CookieMissing object={embed_code}>
				<Trusted>{embed_code}</Trusted>
			</CookieMissing>
		</div>
	)
}

export const HighlightedContent: React.FC<{
	highlighted: DPArticle | DPPress | DPVideoPage | DPPodcast
}> = (data) => {
	const t = useTranslation()
	const {printDate} = useDpDateToPrettyDate()
	const {_type, url, tags, date} = data.highlighted
	let {title, description} = data.highlighted

	if (
		data.highlighted._type === 'article' ||
		data.highlighted._type === 'press' ||
		data.highlighted._type === 'podcast'
	) {
		title = data.highlighted.alt_title ? data.highlighted.alt_title : title
		description = data.highlighted.alt_description
			? data.highlighted.alt_description
			: description
	}

	return (
		<div className={styles.content()}>
			{_type && (
				<p className={styles.content.type()}>{switchPagetypes(_type, t)}</p>
			)}
			<Theme.H2 as="h3" className={styles.content.title()}>
				<Link href={url}>{title}</Link>
			</Theme.H2>
			{data.highlighted._type !== 'podcast' && date && (
				<p className={styles.content.date()}>{printDate(date)}</p>
			)}
			{description && (
				<div className={styles.content.text()}>
					<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

function switchPagetypes(type: DPNode['_type'], t) {
	switch (type) {
		case 'article':
			return t.readingroom.types.article
		case 'press':
			return t.readingroom.types.press
		case 'video_page':
			return t.readingroom.types.video
		case 'podcast':
			return t.readingroom.types.podcast
		default:
			return null
	}
}
