import {DPImage} from 'types/DPTypes'
import {Image} from './image'
import {Mod} from 'util/style'
import React from 'react'
import css from './bg.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const BgFixed: React.FC<{
	image: DPImage
	priority?: boolean
	sizes?: string | undefined
	hideOverlay?: boolean
	className?: string
	mod?: Mod<'cases' | 'gradient' | 'contain'>
	mp4?: string
}> = ({image, priority, sizes, mp4, hideOverlay, className, mod}) => {
	if (!image && !mp4) return null
	const showOverlay = !hideOverlay
	return (
		<div className={styles.bg.with(className).mod(mod)()}>
			{image?.src && (
				<Image {...image} fill priority={priority} sizes={sizes} />
			)}
			{mp4 && (
				<video autoPlay muted loop className={styles.bg.video()}>
					<source src={mp4} type="video/mp4" />
				</video>
			)}
			{showOverlay && (
				<div
					className={styles.bg.overlay()}
					style={{
						opacity: ((image && image.overlay_percentage) || 65) / 100
					}}
				/>
			)}
		</div>
	)
}
