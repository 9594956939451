import {FilterTag} from 'layout/search/filtertag'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './filters.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useImecSearchParams} from 'util/useImecSearchParams'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export type FiltersType = {
	filters: Array<FilterType>
	baseUrl: string
}

export type FilterType = {
	key: string
	title: string
	options: Array<OptionType>
}

export type OptionType = {
	name: string
	id: number
}

export const Filters: React.FC<{
	filters: Array<FilterType>
}> = ({filters}) => {
	let {terms, toggleTerm, setParams} = useImecSearchParams()
	const t = useTranslation()

	const termsSet = new Set<number>(terms)
	let allOptions = []
	filters.forEach((filter) => {
		allOptions = [...allOptions, ...filter.options]
	})
	const selectedOption = allOptions.filter((option) => termsSet.has(option.id))

	if (!filters || filters.length === 0) return null

	return (
		<div className={styles.filters()}>
			<Theme.Container>
				{filters.map((item, i) => (
					<FiltersItem
						title={item.title}
						options={item.options.filter((option) => !termsSet.has(option.id))}
						key={item.key}
					/>
				))}
				<button
					onClick={() => setParams({})}
					aria-label={t.filters.clear}
					className={styles.filters.clear()}
				>
					{t.filters.clear}
				</button>
				{selectedOption?.length > 0 && (
					<div className={styles.filters.tags()}>
						{selectedOption.map((option) => (
							<FilterTag
								onClick={() => toggleTerm(option.id)}
								key={option.id}
								mod="white"
							>
								{option.name}
							</FilterTag>
						))}
					</div>
				)}
			</Theme.Container>
		</div>
	)
}

const FiltersItem: React.FC<{
	title: string
	options: Array<OptionType>
}> = ({title, options}) => {
	const {termsSet, toggleTerm} = useImecSearchParams()
	const activeTerm = options.find((option) => termsSet.has(option.id))
	const t = useTranslation()

	return (
		<div className={styles.item()}>
			<select
				className={styles.item.select()}
				onChange={(e) => toggleTerm(e.target.value)}
				value={activeTerm?.id || 'DEFAULT'}
				aria-label={t.select + title}
			>
				<option disabled value="DEFAULT">
					{title}
				</option>
				{options.map((option) => (
					<option value={option.id} key={option.id}>
						{option.name}
					</option>
				))}
			</select>
		</div>
	)
}
