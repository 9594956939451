import {Icon} from 'assets/icon'
import {Mod} from 'util/style'
import React from 'react'
import css from './filtertag.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const FilterTag: React.FC<{
	onClick: () => void
	locked?: boolean
	inactive?: boolean
	passive?: boolean
	mod?: Mod<'white'>
	children: React.ReactNode
}> = ({onClick, locked, inactive, passive, mod, children}) => {
	return (
		<div
			onClick={onClick}
			className={styles.filtertag.is({locked, inactive, passive}).mod(mod)()}
		>
			<div className={styles.filtertag.content()}>{children}</div>
			<span className={styles.filtertag.icon()}>
				<Icon icon="Close" />
			</span>
		</div>
	)
}
