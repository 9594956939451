import {BlockContext} from 'blocks/blocks'
import {useContext} from 'react'
import {DPBlock} from 'types/DPTypes'

export const supportedAnchorBlockTypes = [
	'agenda',
	'articles',
	'backgroundblock',
	'blocks2',
	'calendar',
	'cases',
	'centered',
	'contact',
	'contactform',
	'downloads',
	'eloqua',
	'faq',
	'gallery',
	'iconoverview',
	'iframeblock',
	'imageblock',
	'imagetext',
	'jobs',
	'links',
	'list',
	'logos',
	'news',
	'newsletter',
	'offices',
	'overview',
	'process',
	'products',
	'productspreview',
	'profile',
	'prototype',
	'readingroom',
	'related',
	'relatedjobs',
	'request',
	'search',
	'services',
	'share',
	'specifications',
	'sponsors',
	'testimonials',
	'textblock',
	'videoblock'
] as const
export type SupportedAnchorBlockType =
	(typeof supportedAnchorBlockTypes)[number]
export type NonSupportedAnchorBlockType = Exclude<
	DPBlock['_type'],
	SupportedAnchorBlockType
>

export function useIDFromAnchorBlock<T extends SupportedAnchorBlockType>(
	block: DPBlock & {_type: T}
) {
	const context = useContext(BlockContext)
	const index = context.blocks.findIndex((bl) => bl.id === block.id)
	const prevBlock = context.blocks[index - 1]

	if (!prevBlock) return null
	if (prevBlock._type !== 'anchor') return null
	return prevBlock.id
}
