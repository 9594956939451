import {
	DPSpecificationItemBlock,
	DPSpecificationLongitemBlock,
	DPSpecificationsBlock,
	DPWysiwyg
} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import {useState} from 'react'
import AnimateHeight from 'react-animate-height'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './specifications.module.scss'

const styles = fromModule(css)

export const Specifications: React.FC<DPSpecificationsBlock> = (data) => {
	const {_type, title, specification_items} = data
	const items = specification_items
	const [isOpen, setOpen] = useState(true)
	const id = useIDFromAnchorBlock(data)
	if (!items || items.length === 0) return null

	const firstHalft = items.slice(
		0,
		items.length === 1 ? 1 : Math.floor(items.length / 2)
	)
	const secondHalf =
		items.length === 1
			? []
			: items.slice(Math.floor(items.length / 2), items.length)

	return (
		<Block type={_type} className={styles.specifications()} anchorID={id}>
			<Theme.Container>
				<div className={styles.specifications.title()}>
					<a onClick={() => setOpen(!isOpen)}>
						<Theme.H2
							className={styles.specifications.title.text.is({
								open: isOpen
							})()}
							mod={['blueline', 'toggle']}
						>
							{title || 'Specifications'}
						</Theme.H2>
					</a>
				</div>
				<AnimateHeight height={isOpen ? 'auto' : 0}>
					<div className={styles.specifications.items()}>
						<div className={styles.specifications.items.col()}>
							{firstHalft.map(
								(
									item: DPSpecificationItemBlock | DPSpecificationLongitemBlock
								) => {
									if (item?._type === 'specification_longitem')
										return (
											<SpecificationsItem
												key={item.lab}
												label={item.lab}
												value={item.val}
											/>
										)
									return <SpecificationsItem key={item.label} {...item} />
								}
							)}
						</div>
						{secondHalf && secondHalf.length !== 0 && (
							<div className={styles.specifications.items.col()}>
								{secondHalf.map(
									(
										item:
											| DPSpecificationItemBlock
											| DPSpecificationLongitemBlock
									) => {
										if (item?._type === 'specification_longitem')
											return (
												<SpecificationsItem
													key={item.lab}
													label={item.lab}
													value={item.val}
												/>
											)
										return <SpecificationsItem key={item.label} {...item} />
									}
								)}
							</div>
						)}
					</div>
				</AnimateHeight>
			</Theme.Container>
		</Block>
	)
}

const SpecificationsItem: React.FC<{label: string; value: DPWysiwyg}> = ({
	label,
	value
}) => {
	if (!label && !value) return null

	return (
		<div className={styles.item()}>
			<div className={styles.item.row()}>
				<div className={styles.item.label()}>{label && <p>{label}</p>}</div>
				<div className={styles.item.value()}>
					{value && <Theme.Wysiwyg>{value}</Theme.Wysiwyg>}
				</div>
			</div>
		</div>
	)
}
