import React from 'react'
import {DPTagTerm} from 'types/DPTypes'
import {useQueryString} from 'util/createQueryString'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {fromModule} from 'util/styler/Styler'
import css from './tags.module.scss'

const styles = fromModule(css)

export const Tags: React.FC<{tags: DPTagTerm[]; tabIndex?: number}> = ({
	tags,
	tabIndex
}) => {
	const {readingroom} = useLinks()
	const createQueryString = useQueryString()

	if (!tags || tags.length === 0) return null

	return (
		<div className={styles.tags()}>
			{tags.slice(0, 2).map((tag) => (
				<div key={tag.id} className={styles.tags.item()}>
					<Link
						tabIndex={tabIndex}
						href={readingroom.url + '?' + createQueryString('tags', tag.name)}
						className={styles.tags.item.tag()}
					>
						{tag?.name}
					</Link>
				</div>
			))}
			{tags.length > 2 && <span className={styles.tags.dots()}>...</span>}
		</div>
	)
}
