import {useState} from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './form.module.scss'

const styles = fromModule(css)

export const FormSimpleCheckbox: React.FC<{
	value: boolean
	onChange: (checked: boolean) => void
	label: string
	name: string
	required?: boolean
}> = ({value, required = false, onChange, name, label}) => {
	const [focus, setFocus] = useState(false)
	const isAttrs = {
		focus,
		filled: value,
		error: false
	}
	const theme = 'checkbox'

	return (
		<div className={styles.field.mod(theme)()}>
			<label className={styles.field.label.is(isAttrs)()}>
				<input
					type="checkbox"
					onFocus={(e) => setFocus(true)}
					checked={value}
					onBlur={(e) => setFocus(false)}
					className={styles.field.label.field.is(isAttrs)()}
					name={name}
					onChange={(e) => onChange(e.target.checked)}
				/>
				<div className={styles.field.label.text.is(isAttrs)()}>
					{label}
					{required && <span>&nbsp;*</span>}
				</div>
			</label>
		</div>
	)
}
