import {HeroBreadcrumbs, HeroTitle} from 'blocks/hero/hero'
import {
	DPImage,
	DPProduct,
	DPProductheroBlock,
	DPProductheroFilterBlock
} from 'types/DPTypes'

import {useApp} from 'AppContext'
import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {BgFixed} from 'layout/partials/bg'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {ImageType} from 'types/basictypes'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './producthero.module.scss'

const styles = fromModule(css)

export const ProductHero: React.FC<DPProductheroBlock> = (data) => {
	const {_type, background, filters} = data
	const nofilters = !filters || filters.length === 0

	return (
		<Block type={_type} className={styles.producthero()}>
			<ProductHeroHero background={background} nofilters={nofilters} />
			<ProductHeroFilters filters={filters} />
		</Block>
	)
}

const ProductHeroHero: React.FC<{
	background?: ImageType
	nofilters?: boolean
}> = ({background, nofilters}) => {
	const {node} = useApp()

	const hasBg = !!background
	if (node?._type !== 'product') return null

	return (
		<div
			className={styles.hero
				.mod('producthero')
				.mod({image: hasBg})
				.mod({nofilters})()}
		>
			{hasBg && <BgFixed image={background} />}
			<HeroBreadcrumbs pageTitle={node.title} />
			<Theme.Container>
				<div className={styles.hero.row()}>
					<ProductHeroImage image={node.image} />
					<ProductHeroContent product={node} />
				</div>
			</Theme.Container>
		</div>
	)
}

const ProductHeroImage: React.FC<{
	image: DPImage
}> = ({image}) => (
	<div className={styles.image()}>
		{image && (
			<div className={styles.image.container()}>
				<Image {...image} sizes="(max-width: 768px) 100vw, 640px" />
			</div>
		)}
	</div>
)

const ProductHeroContent: React.FC<{
	product: DPProduct
}> = ({product}) => {
	return (
		<div className={styles.content()}>
			<div className={styles.content.back()}>
				<Link href={product.parent?.url} className={styles.content.back.link()}>
					<div className={styles.content.back.link.icon()}>
						<Icon icon="ArrowLeft" />
					</div>
					<p className={styles.content.back.link.text()}>Show all</p>
				</Link>
			</div>
			<HeroTitle>{product.title}</HeroTitle>
			{product.description && (
				<div className={styles.content.description()}>
					<Theme.Wysiwyg>{product.description}</Theme.Wysiwyg>
				</div>
			)}
		</div>
	)
}

const ProductHeroFilters: React.FC<{
	filters: DPProductheroFilterBlock[]
}> = ({filters}) => {
	if (!filters || filters.length === 0) return null
	if (filters[0] && !filters[0].label && !filters[0].value) return null

	return (
		<div className={styles.filters()}>
			<Theme.Container>
				<div className={styles.filters.row()}>
					{filters.map((filter, i) => (
						<div key={i} className={styles.filters.row.item()}>
							<p className={styles.filters.row.item.filter()}>{filter.label}</p>
							<p className={styles.filters.row.item.label()}>{filter.value}</p>
						</div>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}
