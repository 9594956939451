import {DPSponsorsBlock, DPSponsorsItemBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './sponsors.module.scss'

const styles = fromModule(css)

export const Sponsors: React.FC<DPSponsorsBlock> = (data) => {
	const {_type, title, logos} = data
	const id = useIDFromAnchorBlock(data)
	if (!title && !logos?.length) return null

	return (
		<Block type={_type} anchorID={id} className={styles.sponsors()}>
			<Theme.Container>
				{title && (
					<Theme.H2 mod={'blueline'} className={styles.sponsors.title()}>
						{title}
					</Theme.H2>
				)}
				<div className={styles.logos()}>
					<div className={styles.logos.items()}>
						{logos &&
							logos.length &&
							logos.map((item, i) => (
								<div key={i} className={styles.logos.items.item()}>
									<Logo {...item} />
								</div>
							))}
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}

const Logo: React.FC<DPSponsorsItemBlock> = ({image, link}) => {
	if (!image) return null
	const imgWidth = 150 / (image?.height / image?.width)

	return (
		<div className={styles.logo()} style={{width: imgWidth}}>
			{!!link && (
				<Link href={link}>
					<Image {...image} height={150} width={imgWidth} sizes="175px" />
				</Link>
			)}
			{!link && (
				<Image {...image} height={150} width={imgWidth} sizes="175px" />
			)}
		</div>
	)
}
