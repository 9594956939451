import {Block} from 'blocks/block'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import React from 'react'
import {DPProfileBlock} from 'types/DPTypes'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './profile.module.scss'

const styles = fromModule(css)

export const Profile: React.FC<DPProfileBlock> = (data) => {
	const {_type, image, image_contain, text} = data
	const id = useIDFromAnchorBlock(data)

	if (!image && !text) return null

	return (
		<Block type={_type} anchorID={id} className={styles.profile()}>
			<Theme.Container>
				<div className={styles.profile.row()}>
					{image?.src && (
						<div className={styles.profile.row.image()}>
							<Image
								{...image}
								fill
								sizes="(max-width: 768px) 100vw, 460px"
								style={{objectFit: image_contain ? 'contain' : 'cover'}}
							/>
						</div>
					)}
					{text && (
						<div className={styles.profile.row.content()}>
							<Theme.Wysiwyg>{text}</Theme.Wysiwyg>
						</div>
					)}
				</div>
			</Theme.Container>
		</Block>
	)
}
