import {Carousel, useCarousel} from '@codeurs/carousel'
import React, {useState} from 'react'
import {DPImage, DPImagetextBlock} from 'types/DPTypes'
import {ImageType, WysiwygType} from 'types/basictypes'

import {Block} from 'blocks/block'
import {CarouselNav} from 'layout/carousel/carouselnav'
import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import {ImageProps} from 'next/image'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import {useInterval} from 'util/useinterval'
import css from './imagetext.module.scss'

const styles = fromModule(css)

export const Imagetext: React.FC<DPImagetextBlock> = (data) => {
	const {_type, images, image_left, text} = data
	const id = useIDFromAnchorBlock(data)

	return (
		<Block
			type={_type}
			className={styles.imagetext.mod({slider: images && images.length > 1})()}
			anchorID={id}
		>
			<Theme.Container>
				<div className={styles.imagetext.row.mod({right: !image_left})()}>
					<div className={styles.imagetext.row.col()}>
						<ImagetextImgcontainer images={images} />
					</div>
					<div className={styles.imagetext.row.col()}>
						<ImagetextContent text={text} />
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}

const ImagetextContent: React.FC<{
	text: WysiwygType
}> = (data) => {
	const {text} = data

	return (
		<div className={styles.content()}>
			{text && <Theme.Wysiwyg>{text}</Theme.Wysiwyg>}
		</div>
	)
}

const ImagetextImage: React.FC<{image: DPImage & ImageProps}> = ({image}) => {
	if (!image.src) return null
	return <Image {...image} fill style={{objectFit: 'cover'}} sizes="460px" />
}

const ImagetextImgcontainer: React.FC<{
	images: Array<ImageType>
}> = (data) => {
	const {images} = data
	const carousel = useCarousel()
	const [isTouched, setIsTouched] = useState(false)

	useInterval(() => {
		if (isTouched) return
		if (carousel.hasNext()) carousel.goNext()
		else carousel.goTo(0)
	}, 7500)

	if (images && images.length === 1)
		return (
			<div className={styles.imgcontainer()}>
				<ImagetextImage image={images[0]} />
			</div>
		)

	return (
		<div
			className={styles.imgcontainer()}
			onTouchStart={() => setIsTouched(true)}
			onMouseDown={() => setIsTouched(true)}
		>
			<Carousel {...carousel}>
				{images &&
					images.map((image, i) => (
						<div key={i} className={styles.imgcontainer.slide()}>
							<ImagetextImage image={image} />
						</div>
					))}
			</Carousel>
			<div className={styles.imgcontainer.nav()}>
				<CarouselNav carousel={carousel} items={images} />
			</div>
		</div>
	)
}
