import { fromModule } from 'util/styler/Styler'
import css from './loader.module.scss'

const styles = fromModule(css)

export const Loader = ({ ...props }) => {
	return (
		<div className={styles.loader.mergeProps(props)()}>
			<div className={styles.loader.circle()} />
		</div>
	)
}
