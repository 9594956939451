'use client'

import {DPBlock} from 'types/DPTypes'
import {Suspense} from 'react'
import {blocks} from 'blocks'

export const Paragraph: React.FC<DPBlock & {firstBlock?: boolean}> = (
	block
) => {
	const Component = blocks[block._type]
	if (!Component) {
		return null
	}
	if (block._type === 'contactform')
		return (
			<Suspense>
				<Component {...block} />
			</Suspense>
		)
	if (block._type === 'eloqua')
		return (
			<Suspense>
				<Component {...block} />
			</Suspense>
		)
	if (block._type === 'products')
		return (
			<Suspense>
				<Component {...block} />
			</Suspense>
		)
	if (block._type === 'reading_room')
		return (
			<Suspense>
				<Component {...block} />
			</Suspense>
		)
	if (block._type === 'icon_overview')
		return (
			<Suspense>
				<Component {...block} />
			</Suspense>
		)
	return <Component {...block} />
}
