import {
	DPServicesBlock,
	DPServicesItemBlock,
	DPServicesLinkBlock,
	DPWysiwyg
} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {BgFixed} from 'layout/partials/bg'
import {Cardpreview} from 'layout/preview/cardpreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './services.module.scss'

const styles = fromModule(css)

export const Services: React.FC<DPServicesBlock> = (data) => {
	const {_type, background_image, text, display_right = false, items} = data
	const id = useIDFromAnchorBlock(data)
	const singleItem = items && items.length <= 1

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.services.mod({bg: background_image})()}
		>
			{background_image && (
				<BgFixed
					image={background_image}
					className={styles.services.bg_container()}
				/>
			)}
			<Theme.Container>
				<div className={styles.services.row.mod({single: singleItem})()}>
					<div className={styles.services.row.content()}>
						<ServicesContent text={text} />
					</div>
					<div
						className={styles.services.row.items.mod({
							bg: background_image?.src
						})()}
					>
						{items?.length > 0 && (
							<div className={styles.items()}>
								{items.map((item) => (
									<div className={styles.items.item()} key={item.id}>
										<ServicesItem item={item} />
									</div>
								))}
							</div>
						)}
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}

const ServicesItem: React.FC<{
	item: DPServicesItemBlock | DPServicesLinkBlock
}> = ({item}) => {
	if (item._type === 'services_link') {
		return (
			<Cardpreview
				url={item.link?.url}
				image={item.link?.image}
				text={`
				<h4>${item.link?.title}</h4>
				<p>${item.link?.description}</p>
			`}
				display_background_dark={true}
			/>
		)
	}

	return (
		<Cardpreview
			url={item.link?.url}
			image={item.image}
			text={item.text}
			display_background_dark={true}
		/>
	)
}

const ServicesContent: React.FC<{
	text: DPWysiwyg
}> = ({text}) => {
	if (!text) return null
	return (
		<div className={styles.content()}>
			{text && <Theme.Wysiwyg mod="inherit">{text}</Theme.Wysiwyg>}
		</div>
	)
}
