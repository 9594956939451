import {DPInpagenavBlock, DPLink} from 'types/DPTypes'
import React, {useState} from 'react'

import {Block} from 'blocks/block'
import {Link} from 'util/link'
import {Theme} from 'layout/theme'
import css from './inpagenav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useAutoHide} from 'util/autohide'

const styles = fromModule(css)

type InpagenavType = {
	cta?: DPLink
	displayDark?: boolean
	links: Array<DPLink>
}

type AnchorType = {
	id: string
	title: string
}

export const Inpagenav: React.FC<DPInpagenavBlock> = (data) => {
	const {node} = useApp()
	const {_type, cta, links, display_dark = false} = data
	const {ref} = useAutoHide<HTMLDivElement>()
	const [open, setOpen] = useState(false)
	const pageTitle = node?.title

	let extraLinks = []
	if (node) {
		extraLinks = ('blocks' in node ? node.blocks : [])
			.filter((bl) => bl && bl._type === 'anchor')
			.map((bl) => ({
				id: '#' + (bl as AnchorType).id,
				label: (bl as AnchorType).title
			}))
	}

	if (!links?.length && !extraLinks.length && !cta) return null

	return (
		<Block
			ref={ref}
			type={_type}
			className={styles.inpagenav.is({dark: display_dark})()}
		>
			<div className={styles.inpagenav.row()}>
				<div>
					<button
						onClick={() => setOpen(!open)}
						className={styles.inpagenav.hamburger()}
					>
						<span className={styles.inpagenav.hamburger.bar()} />
						<span className={styles.inpagenav.hamburger.bar()} />
						<span className={styles.inpagenav.hamburger.bar()} />
					</button>
					<InpagenavItems
						links={links || []}
						extraLinks={extraLinks}
						displayDark={display_dark}
					/>
				</div>
				{cta?.url && (
					<div>
						<div className={styles.inpagenav.button()}>
							<Theme.Button
								href={cta.url.replace('[TITLE]', encodeURIComponent(pageTitle))}
								mod={['rounded', 'white']}
							>
								<Theme.ButtonText>{cta.title}</Theme.ButtonText>
								<Theme.ButtonIcon />
							</Theme.Button>
						</div>
					</div>
				)}
			</div>
			<InpagenavItems
				links={links || []}
				extraLinks={extraLinks}
				displayDark={display_dark}
				mod="mobile"
				open={open}
			/>
		</Block>
	)
}

const InpagenavItems: React.FC<{
	links: Array<DPLink>
	extraLinks: Array<{
		id: string
		label: string
	}>
	displayDark?: boolean
	mod?: 'mobile'
	open?: boolean
}> = ({links, extraLinks, displayDark, mod, open}) => {
	let internalLinks = links.map((link) => {
		return {
			id: link.url,
			label: link.title
		}
	})

	return (
		<div className={styles.items.mod(mod).is({open})()}>
			{[...internalLinks, ...extraLinks].map(
				(item, i) =>
					item.id &&
					item.label && (
						<div key={i} className={styles.items.item()}>
							<Link
								href={item.id}
								className={styles.items.item.link.is({
									dark: displayDark
								})()}
							>
								{item.label}
							</Link>
						</div>
					)
			)}
		</div>
	)
}
