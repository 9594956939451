import AnimateHeight from 'react-animate-height'
import {DPTagTerm} from 'types/DPTypes'
import {Icon} from 'assets/icon'
import {Mod} from 'util/style'
import React from 'react'
import css from './searchbar.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const SearchbarPopular: React.FC<{
	tags: Array<DPTagTerm>
	onClickTag: (tag: DPTagTerm) => void
}> = ({tags, onClickTag}) => {
	if (!tags.length) return null

	const {
		readingroom: {popular_topics}
	} = useTranslation()

	return (
		<div className={styles.popular()}>
			{popular_topics}:
			<span className={styles.popular.topics()}>
				{tags.map((tag) => (
					<div
						className={styles.popular.topics.item()}
						onClick={() => onClickTag(tag)}
						key={tag.id}
					>
						{tag.name}
					</div>
				))}
			</span>
		</div>
	)
}

export const SearchbarInput: React.FC<{
	query: string
	onSearch: (query: string) => void
	useSuggestions?: boolean
	mod?: Mod<'dark'>
}> = ({query, mod, onSearch}) => {
	const {
		readingroom: {searchbar}
	} = useTranslation()

	return (
		<div className={styles.input.mod(mod)()}>
			<form
				role="search"
				onSubmit={(e) => {
					e.preventDefault()
					onSearch((e.target as any)?.search?.value)
				}}
				className={styles.input.container()}
			>
				<input
					name="search"
					type="text"
					className={styles.input.container.input()}
					placeholder={searchbar}
					defaultValue={query}
				/>
				<button
					type="submit"
					value="search"
					id="searchbarsubmitter"
					className={styles.input.container.icon()}
					aria-label={searchbar}
				>
					<Icon icon="Search" />
				</button>
			</form>
		</div>
	)
}

export const SearchbarToggler: React.FC<{
	open: boolean
	onToggle: () => void
	children: React.ReactNode
}> = ({open, onToggle, children}) => {
	return (
		<div className={styles.toggler()}>
			<a className={styles.toggler.toggle.is({open})()} onClick={onToggle}>
				<div className={styles.toggler.toggle.label()}>
					{children}
					<Icon icon="ChevronDown" className={styles.toggler.toggle.icon()} />
				</div>
			</a>
		</div>
	)
}

export const SearchbarMore: React.FC<{
	open: boolean
	children: React.ReactNode
}> = ({open, children}) => {
	return (
		<div className={styles.more()}>
			<AnimateHeight height={open ? 'auto' : 0} animateOpacity={true}>
				{children}
			</AnimateHeight>
		</div>
	)
}
