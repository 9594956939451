import {Blocks} from 'blocks/blocks'
import {DPEventNode} from 'types/DPTypes'
import {HeroInfo} from 'blocks/hero/hero'
import {Paragraph} from 'blocks/paragraph'
import React from 'react'
import {createEventInfo} from 'util/createEventInfo'
import {useApp} from 'AppContext'

export const Event: React.FC<DPEventNode> = (node) => {
	return (
		<Blocks blocks={node.blocks}>
			{node.blocks.map((block) => (
				<Paragraph {...block} key={block.id} />
			))}
		</Blocks>
	)
}

export const EventHeroInfo: React.FC = () => {
	const {node} = useApp()
	if (node?._type !== 'event') return null

	return (
		<HeroInfo>
			{createEventInfo({
				date: node.date,
				end_date: node.end_date,
				location: node.location
			})}
		</HeroInfo>
	)
}
