import {useEffect, useState} from 'react'

import {isBrowser} from 'browser-or-node'
import {useSearchParams} from 'next/navigation'

export const useSearchParamsAreLoaded = () => {
	const [isLoaded, setIsLoaded] = useState(false)
	const searchParams = useSearchParams()

	useEffect(() => {
		if (!isBrowser) return
		if (isLoaded) return
		if (!window.location.search) {
			setIsLoaded(true)
			return
		}

		const queryKeys = []
		searchParams.forEach((value, key) => {
			queryKeys.push(key)
		})
		if (!queryKeys.length) return
		if (queryKeys.length === 1 && queryKeys[0] === 'slug') return
		setIsLoaded(true)
	}, [])
	return isLoaded
}
