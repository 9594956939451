import React, {Suspense} from 'react'

import {BgFixed} from 'layout/partials/bg'
import {Tags} from 'layout/partials/tags'
import {Theme} from 'layout/theme'
import {ImageProps} from 'next/image'
import {DPNode} from 'types/DPTypes'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './relatedpreview.module.scss'

const styles = fromModule(css)

export const Relatedpreview: React.FC<
	DPNode & {sizes?: ImageProps['sizes']}
> = (data) => {
	const {url, image, sizes, tags} = data
	let {title} = data
	const t = useTranslation()

	if (data._type === 'article' || data._type === 'press') {
		title = data.alt_title ? data.alt_title : title
	}

	const label = t.getNodeLabel(data)

	return (
		<div className={styles.relatedpreview()}>
			<Link href={url} className={styles.relatedpreview.image()}>
				<BgFixed
					image={image}
					sizes={sizes}
					className={styles.relatedpreview.image.bg()}
					mod="gradient"
				/>
			</Link>
			{label && <p className={styles.relatedpreview.label()}>{label}</p>}
			<div className={styles.relatedpreview.content()}>
				{tags && tags.length !== 0 && (
					<div className={styles.relatedpreview.content.tags()}>
						<Suspense>
							<Tags tags={tags} />
						</Suspense>
					</div>
				)}
				<Theme.H5 className={styles.relatedpreview.content.title()}>
					<Link href={url}>{title}</Link>
				</Theme.H5>
			</div>
		</div>
	)
}
