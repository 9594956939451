import React, {useState} from 'react'
import {DPAgendaBlock, DPEvent} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import {createEventInfo} from 'util/createEventInfo'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './agenda.module.scss'

const styles = fromModule(css)

export const Agenda: React.FC<DPAgendaBlock> = (data) => {
	const [moreCounter, updateCounter] = useState(1)
	const t = useTranslation()
	const id = useIDFromAnchorBlock(data)

	const {events: allEvents, past_events: past} = data
	const events = allEvents.slice(0, moreCounter * 10)

	return (
		<Block type={data._type} anchorID={id} className={styles.agenda()}>
			<AgendaEvents events={events} past={past} />
			{moreCounter < 5 && allEvents.length !== events.length && (
				<div className={styles.agenda.more()}>
					<div
						className={styles.agenda.more.button()}
						onClick={() => updateCounter(moreCounter + 1)}
					>
						{t.agenda.readmore}
					</div>
				</div>
			)}
		</Block>
	)
}

const AgendaEvents: React.FC<{
	events: Array<DPEvent>
	past: boolean
}> = (data) => {
	const {events, past} = data
	if (!events || events.length === 0) {
		return <AgendaEmpty past={past} />
	}

	return (
		<div className={styles.events()}>
			{events.map((event) => (
				<Event {...event} key={event.node_id} />
			))}
		</div>
	)
}

const AgendaEmpty: React.FC<{past: boolean}> = ({past}) => {
	const t = useTranslation()

	return (
		<div className={styles.empty()}>
			<Theme.Container>
				<Theme.H3 className={styles.empty.title()}>
					{past ? t.agenda.no_results_past : t.agenda.no_results}
				</Theme.H3>
			</Theme.Container>
		</div>
	)
}

const Event: React.FC<DPEvent> = (data) => {
	const {title, location, date, end_date, url, description, hide_description} =
		data

	const eventInfo = createEventInfo({date, end_date, location})

	return (
		<>
			<div className={styles.events.space()} />
			<Link href={url} className={styles.events.event()}>
				<Theme.Container>
					{date && <h3 className={styles.events.event.date()}>{eventInfo}</h3>}
					<h2>{title}</h2>
					{description && !hide_description && (
						<Theme.Wysiwyg className={styles.events.event.description()}>
							{description}
						</Theme.Wysiwyg>
					)}
				</Theme.Container>
			</Link>
		</>
	)
}
