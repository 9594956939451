import {Carousel, useCarousel} from '@codeurs/carousel'
import {DPNode, DPRelatedBlock} from 'types/DPTypes'

import {Block} from 'blocks/block'
import {CarouselControls} from 'layout/carousel/carouselcontrols'
import {Relatedpreview} from 'layout/preview/relatedpreview'
import {Theme} from 'layout/theme'
import React from 'react'
import {CarouselType} from 'types/basictypes'
import {Link} from 'util/link'
import {useLinks} from 'util/links'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './related.module.scss'

const styles = fromModule(css)

export const Related: React.FC<DPRelatedBlock> = (data) => {
	const {title, link_title, items} = data
	const carousel = useCarousel()
	const {readingroom} = useLinks()
	const id = useIDFromAnchorBlock(data)

	if (!items || items.length === 0) return null

	//Make an array where each element is an array of 4 articles
	const source = items.slice()
	let articleRows: Array<Array<DPNode>> = []
	for (let i = 0; 0 < items.length && i < 3; i++) {
		articleRows[i] = source.splice(0, 4)
	}

	return (
		<Block type="related" anchorID={id} className={styles.related()}>
			<Theme.Container>
				<div className={styles.related.top()}>
					<Link
						href={link_title?.url || readingroom?.url}
						className={styles.related.top.title()}
					>
						<Theme.H2 mod={['blueline', 'arrow']}>{title}</Theme.H2>
					</Link>
					<div className={styles.related.top.nav()}>
						<CarouselControls carousel={carousel} />
					</div>
				</div>
			</Theme.Container>
			<RelatedItems carousel={carousel} items={articleRows} />
		</Block>
	)
}

const RelatedItems: React.FC<{
	carousel: CarouselType
	items: Array<Array<DPNode>>
}> = (data) => {
	const {carousel, items} = data

	return (
		<div className={styles.items()}>
			<Carousel {...carousel}>
				{items &&
					items.map((articles, i) => (
						<div
							key={i}
							className={styles.items.block.is({
								active: carousel.isActive(i)
							})()}
						>
							<div className={styles.items.block.left()}>
								<Relatedpreview
									{...articles[0]}
									sizes="(max-width: 768px) 100vw, (max-width: 1580px) 50vw, 745px"
								/>
							</div>
							<div className={styles.items.block.right()}>
								<div className={styles.items.block.right.top()}>
									<div className={styles.items.block.right.top.item()}>
										<Relatedpreview
											{...articles[1]}
											sizes="(max-width: 768px) 100vw, (max-width: 1580px) 50vw, 745px"
										/>
									</div>
								</div>
								<div className={styles.items.block.right.bottom()}>
									<div className={styles.items.block.right.bottom.item()}>
										<Relatedpreview
											{...articles[2]}
											sizes="(max-width: 600px) 100vw, (max-width: 768px) 50vw, (max-width: 1580px) 25vw, 360px"
										/>
									</div>
									<div className={styles.items.block.right.bottom.item()}>
										<Relatedpreview
											{...articles[3]}
											sizes="(max-width: 600px) 100vw, (max-width: 768px) 50vw, (max-width: 1580px) 25vw, 360px"
										/>
									</div>
								</div>
							</div>
						</div>
					))}
			</Carousel>
		</div>
	)
}
