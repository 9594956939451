import {RefObject, useEffect, useRef} from 'react'

export type AutoHideOptions = {
	scrollRef?: RefObject<HTMLElement>
	addToHeight?: number
}

export const useAutoHide = <E extends HTMLElement = HTMLElement>(
	options?: AutoHideOptions
) => {
	const ref = useRef<E>(null)

	useEffect(() => {
		const dom = ref.current
		if (!dom) return
		const {style} = dom
		let height = 0
		let lastScroll = 0
		let offset = 0
		let top = 0

		const scroll = () => {
			const heightChanged = trackHeight()
			const y = options?.scrollRef?.current
				? options?.scrollRef?.current.scrollTop
				: window.scrollY
			if (heightChanged) lastScroll = y
			const diff = lastScroll - y
			const last = offset
			offset += diff
			if (offset < -height) offset = -height
			if (y <= 0 || offset > 0 || y <= top) offset = 0
			if (last !== offset) style.transform = `translateY(${offset}px)`
			lastScroll = y
			if (y <= height) dom.classList.add('is-top')
			else dom.classList.remove('is-top')
		}

		const trackHeight = () => {
			top = dom.getBoundingClientRect().top
			const newHeight = dom.offsetHeight + (options?.addToHeight || 0)
			const changed = height !== newHeight
			height = newHeight
			return changed
		}

		scroll()

		const target = options?.scrollRef?.current || window

		target.addEventListener('scroll', scroll)

		return () => {
			target.removeEventListener('scroll', scroll)
		}
	}, [options?.scrollRef, options?.addToHeight])

	return {ref}
}
