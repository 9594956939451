'use client'

import {ButtonType, LinkType} from 'types/basictypes'
import {
	DPHeroBlock,
	DPNode,
	DPParentTerm,
	DPProduct,
	DPSubsiteTerm
} from 'types/DPTypes'

import {BgFixed} from 'layout/partials/bg'
import {Block} from 'blocks/block'
import {EventHeroInfo} from 'nodes/event'
import {Fragment} from 'react'
import {Icon} from 'assets/icon'
import {Image} from 'layout/partials/image'
import {Link} from 'util/link'
import {Mod} from 'util/style'
import {Mousescroll} from 'layout/mousescroll'
import {Theme} from 'layout/theme'
import css from './hero.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useLinks} from 'util/links'
import {useRouter} from 'next/navigation'

const styles = fromModule(css)

export const useGetParents = (node: DPNode): {url: string; title: string}[] => {
	const links = useLinks()

	if (!node) return []

	switch (node?._type) {
		case 'page':
			const pageParent = node.parent
			if (!pageParent) return []
			return [...getParents(pageParent), pageParent]
		case 'press':
			return [links.press]
		case 'article':
			return [links.readingroom]
		case 'job':
			return [links.jobs]
		case 'product':
			const productParent = node.parent
			if (!productParent) return []
			return [...getParents(productParent), productParent]
	}
	return []
}

export const getParents = (node: DPNode): DPNode[] => {
	switch (node?._type) {
		case 'page':
			const parent = node.parent
			if (!parent) return []
			return [...getParents(parent), parent]
	}
	return []
}

export const Hero: React.FC<
	Partial<DPHeroBlock> & {
		mod?: Mod<'small'>
		subtitle?: string
		scrollicon?: boolean
		customParents?: {url: string; title: string}[]
	}
> = (data) => {
	const {
		_type,
		background_image,
		subtitle,
		title,
		description,
		buttons,
		back,
		scrollicon = false,
		mp4,
		subsite,
		product,
		mod,
		customParents = []
	} = data
	const {node} = useApp()

	const hasBg = !!background_image
	const hasVideo = !!mp4
	let isDark = !hasBg
	if (background_image?.use_dark_colors) isDark = true
	if (hasVideo) isDark = false

	return (
		<Block
			type={_type}
			className={styles.hero
				.mod({image: hasBg})
				.mod({video: hasVideo})
				.mod({darkcolors: isDark})
				.mod({product})
				.mod(mod)()}
		>
			{(hasBg || hasVideo) && (
				<BgFixed
					image={background_image}
					priority
					sizes="(max-width: 1580px) 100vw, 1580px"
					mp4={mp4?.url}
				/>
			)}
			{!subsite && (
				<HeroBreadcrumbs
					pageTitle={title || node?.title}
					customParents={customParents}
				/>
			)}
			{subsite && <HeroSubsiteInfo subsite={subsite} isDark={isDark} />}
			<div className={styles.hero.content.mod({subsites: subsite})()}>
				<Theme.Container>
					{subtitle && <HeroSubtitle>{subtitle}</HeroSubtitle>}
					<HeroTitle>{title || node?.title}</HeroTitle>
					<EventHeroInfo />
					{description && <HeroDescription>{description}</HeroDescription>}
					{buttons && <HeroButtons buttons={buttons} />}
					{back && <HeroBack {...back} />}
				</Theme.Container>
				{scrollicon && hasBg && (
					<div className={styles.hero.scroll()}>
						<Theme.Container>
							<Mousescroll id="#content" />
						</Theme.Container>
					</div>
				)}
			</div>
			<HeroProduct product={product} />
		</Block>
	)
}

export const HeroBack: React.FC<LinkType> = ({title}) => {
	const router = useRouter()
	const url = undefined
	return (
		<div className={styles.back()}>
			<Link
				className={styles.back.link()}
				href={url}
				onClick={() => {
					if (!url) router.back()
				}}
			>
				<div className={styles.back.link.circle()}>
					<Icon icon="ArrowLeft" />
				</div>
				<p className={styles.back.link.text()}>{title}</p>
			</Link>
		</div>
	)
}

export const HeroSubtitle: React.FC<{children: React.ReactNode}> = ({
	children
}) => {
	return <p className={styles.subtitle()}>{children}</p>
}

export const HeroTitle: React.FC<{children: React.ReactNode}> = ({
	children
}) => <Theme.H1 className={styles.title()}>{children}</Theme.H1>

export const HeroInfo: React.FC<{children: React.ReactNode}> = ({children}) => (
	<div className={styles.info()}>
		<p>{children}</p>
	</div>
)

export const HeroDescription: React.FC<{children: React.ReactNode}> = ({
	children
}) => (
	<div className={styles.description()}>
		<Theme.Wysiwyg mod="smallbtn">{children}</Theme.Wysiwyg>
	</div>
)

export const HeroButtons: React.FC<{buttons?: Array<ButtonType>}> = ({
	buttons
}) => {
	if (!buttons || buttons.length === 0) return null

	return (
		<div className={styles.buttons()}>
			{buttons.map((button, i) => (
				<HeroButton key={i} url={button?.url}>
					{button.title}
				</HeroButton>
			))}
		</div>
	)
}

export const HeroButton: React.FC<{
	children: React.ReactNode
	url: string
}> = ({url, children}) => {
	if (!url) return null

	return (
		<div className={styles.button()}>
			<Theme.Button href={url} mod="rounded">
				<Theme.ButtonText>{children}</Theme.ButtonText>
				<Theme.ButtonIcon />
			</Theme.Button>
		</div>
	)
}

export const HeroLink: React.FC<LinkType> = (data) => {
	const {url, title} = data
	if (!url) return null

	return (
		<div className={styles.link()}>
			<Theme.Link href={url}>
				<Theme.LinkText>{title}</Theme.LinkText>
				<Theme.LinkIcon />
			</Theme.Link>
		</div>
	)
}

export const HeroBreadcrumbs: React.FC<{
	pageTitle: string
	pageurl?: string
	customParents?: {url: string; title: string}[]
}> = (data) => {
	const {pageTitle, pageurl, customParents = []} = data
	const {home} = useLinks()
	const {node} = useApp()

	if (!pageTitle) return null
	const parents = getParents(node)

	return (
		<div className={styles.breadcrumbs()}>
			<Theme.Container>
				<Link
					href={home.url}
					title={home.title}
					className={styles.breadcrumbs.icon()}
				>
					<Icon icon="Home" />
				</Link>
				{(customParents && customParents.length ? customParents : parents).map(
					(parent, i) => (
						<Fragment key={i}>
							<span className={styles.breadcrumbs.divider()}>/</span>
							<Link href={parent.url} className={styles.breadcrumbs.link()}>
								{parent.title}
							</Link>
						</Fragment>
					)
				)}
				<span className={styles.breadcrumbs.divider()}>/</span>
				{!pageurl && (
					<span className={styles.breadcrumbs.link()}>{pageTitle}</span>
				)}
				{pageurl && (
					<Link href={pageurl} className={styles.breadcrumbs.link()}>
						{pageTitle}
					</Link>
				)}
			</Theme.Container>
		</div>
	)
}

export const HeroSubsiteInfo: React.FC<{
	subsite: DPParentTerm<DPSubsiteTerm>
	isDark: boolean
}> = ({subsite, isDark}) => {
	const {node} = useApp()
	if (!node) return null

	const logo = isDark ? subsite.logo_dark : subsite.logo_light
	const activeRoot = subsite.children.find(
		(root) =>
			root.page?.node_id === node.node_id ||
			root.children.find((child) => child.page?.node_id === node.node_id)
	)

	return (
		<div className={styles.subsiteinfo()}>
			<Theme.Container>
				{logo && (
					<div className={styles.subsiteinfo.logo()}>
						<Link
							href={subsite.page?.url}
							className={styles.subsiteinfo.logo.image()}
							style={{width: 30 / (logo.height / logo.width)}}
						>
							<Image {...logo} sizes={undefined} />
						</Link>
					</div>
				)}
				<div className={styles.subsiteinfo.subPages()}>
					{subsite.children.map((root) => (
						<Link
							key={root.id}
							href={root.link?.url || root.page?.url}
							className={styles.subsiteinfo.subPages.item.mod({
								active: root === activeRoot
							})()}
						>
							{root.name}
						</Link>
					))}
				</div>
			</Theme.Container>
		</div>
	)
}

const HeroProduct: React.FC<{product: DPProduct}> = ({product}) => {
	if (!product?.image) return null

	return (
		<div className={styles.product()}>
			<Image {...product.image} sizes="460px" />
		</div>
	)
}
