import {CarouselType} from 'types/basictypes'
import React from 'react'
import css from './carouselnav.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)

export const CarouselNav: React.FC<{
	className?: string
	carousel: CarouselType
	items?: Array<any>
}> = (data) => {
	const {className, carousel} = data
	const t = useTranslation()
	if (Array.from(Array(carousel.total)).length <= 1) return null

	return (
		<div className={styles.carouselnav.with(className)()}>
			{Array.from(Array(carousel.total)).map((_, i) => (
				<button
					key={i}
					tabIndex={0}
					onClick={() => carousel.goTo(i)}
					aria-label={t.carousel.index_arialabel}
					className={styles.carouselnav.item.is({
						active: carousel.current === i
					})()}
				/>
			))}
		</div>
	)
}
