import {Image} from 'layout/partials/image'
import {Theme} from 'layout/theme'
import {ImageProps} from 'next/image'
import React from 'react'
import {DPImage} from 'types/DPTypes'
import {Link} from 'util/link'
import {Mod} from 'util/style'
import {fromModule} from 'util/styler/Styler'
import css from './cardpreview.module.scss'

const styles = fromModule(css)

export type CardpreviewType = CardpreviewImageType &
	CardpreviewContentType & {
		display_background_dark?: boolean
	}

type CardpreviewImageType = {
	url?: string
	image?: DPImage
	image_sizes?: ImageProps['sizes']
	objectFit?: 'cover' | 'contain'
	display_padding?: boolean
	display_fallback?: boolean
}

type CardpreviewContentType = {
	text: string
	text_mod?: Mod<'small' | 'full' | 'top'>
}

export const Cardpreview: React.FC<CardpreviewType> = (data) => {
	const {
		url,
		image,
		image_sizes,
		text,
		text_mod,
		objectFit = 'cover',
		display_padding = false,
		display_fallback = true,
		display_background_dark = false
	} = data

	return (
		<div
			className={styles.cardpreview.mod({bgdark: display_background_dark})()}
		>
			<CardpreviewImage
				url={url}
				image={image}
				image_sizes={image_sizes}
				objectFit={objectFit}
				display_padding={display_padding}
				display_fallback={display_fallback}
			/>
			<CardpreviewContent text={text} text_mod={text_mod} />
		</div>
	)
}

const CardpreviewImage: React.FC<CardpreviewImageType> = ({
	url,
	image,
	image_sizes,
	objectFit,
	display_padding,
	display_fallback
}) => {
	if (!image?.src) return null

	return (
		<Link
			href={url}
			className={styles.image
				.mod({padding: display_padding})
				.mod({fallback: display_fallback})()}
		>
			<Image
				{...image}
				fill
				sizes={
					image_sizes ||
					'(max-width: 600px) 100vw, (max-width: 1024px) 50vw,(max-width: 1720px) 33vw, 420px'
				}
				style={{objectFit}}
			/>
		</Link>
	)
}

export const CardpreviewContent: React.FC<CardpreviewContentType> = ({
	text,
	text_mod
}) => {
	if (!text) return null

	return (
		<div className={styles.content.mod(text_mod)()}>
			<Theme.Wysiwyg mod={['title-line']}>{text}</Theme.Wysiwyg>
		</div>
	)
}
