'use client'

import {Articles} from 'blocks/articles/articles'
import {BackgroundBlock} from 'blocks/backgroundblock/backgroundblock'
import {Blocks2} from 'blocks/blocks2/blocks2'
import {Calendar} from 'blocks/calendar/calendar'
import {Cases} from 'blocks/cases/cases'
import {Contact} from 'blocks/contact/contact'
import {Error} from 'blocks/error/error'
import {Faq} from 'blocks/faq/faq'
import {Gallery} from 'blocks/gallery/gallery'
import {HeroHome} from 'blocks/herohome/herohome'
import {Imageblock} from 'blocks/imageblock/imageblock'
import {Imagetext} from 'blocks/imagetext/imagetext'
import {Links} from 'blocks/links/links'
import {List} from 'blocks/list/list'
import {News} from 'blocks/news/news'
import {Newsletter} from 'blocks/newsletter/newsletter'
import {ProductsPreview} from 'blocks/productspreview/productspreview'
import {Prototype} from 'blocks/prototype/prototype'
import {Readingroom} from 'blocks/readingroom/readingroom'
import {Tellit} from 'blocks/tellit/tellit'
import {Textblock} from 'blocks/textblock/textblock'
import dynamic from 'next/dynamic'
import {Agenda} from './agenda/agenda'
import {Alert} from './alert/alert'
import {Anchor} from './anchor/anchor'
import {Centered} from './centered/centered'
import {Downloads} from './downloads/downloads'
import {HeroBlock} from './hero/heroblock'
import {Iconintro} from './iconintro/iconintro'
import {Iconoverview} from './iconoverview/iconoverview'
import {Inpagenav} from './inpagenav/inpagenav'
import {Logos} from './logos/logos'
import {Offices} from './offices/offices'
import {Overview} from './overview/overview'
import {Process} from './process/process'
import {ProductHero} from './producthero/producthero'
import {Products} from './products/products'
import {Profile} from './profile/profile'
import {Related} from './related/related'
import {Relatedjobs} from './relatedjobs/relatedjobs'
import {Services} from './services/services'
import {Share} from './share/share'
import {Specifications} from './specifications/specifications'
import {Sponsors} from './sponsors/sponsors'
import {Testimonials} from './testimonials/testimonials'
import {VideoBlock} from './videoblock/videoblock'

const Contactform = dynamic(() =>
	import('./contactform/contactform').then((mod) => mod.Contactform)
)

const Eloqua = dynamic(() =>
	import('./eloqua/eloqua').then((mod) => mod.Eloqua)
)

const IframeBlock = dynamic(() =>
	import('./iframeblock/iframeblock').then((mod) => mod.IframeBlock)
)

const Jobs = dynamic(() =>
	import('./jobs_algolia/jobs').then((mod) => mod.Jobs)
)

const Search = dynamic(
	() => import('./search/search').then((mod) => mod.Search),
	{ssr: false}
)

export const blocks = {
	alert: Alert,
	agenda: Agenda,
	anchor: Anchor,
	articles: Articles,
	backgroundblock: BackgroundBlock,
	blocks2: Blocks2,
	calendar: Calendar,
	cases: Cases,
	centered: Centered,
	contact: Contact,
	contactform: Contactform,
	downloads: Downloads,
	eloqua: Eloqua,
	error: Error,
	faq: Faq,
	gallery: Gallery,
	hero: HeroBlock,
	herohome: HeroHome,
	iframeblock: IframeBlock,
	iconintro: Iconintro,
	icon_overview: Iconoverview,
	imageblock: Imageblock,
	imagetext: Imagetext,
	inpagenav: Inpagenav,
	jobs: Jobs,
	links: Links,
	list: List,
	news: News,
	newsletter: Newsletter,
	offices: Offices,
	overview: Overview,
	process: Process,
	profile: Profile,
	prototype: Prototype,
	producthero: ProductHero,
	products: Products,
	productspreview: ProductsPreview,
	related: Related,
	request: Contact,
	relatedjobs: Relatedjobs,
	reading_room: Readingroom,
	services: Services,
	share: Share,
	specifications: Specifications,
	sponsors: Sponsors,
	search: Search,
	tellit: Tellit,
	testimonials: Testimonials,
	textblock: Textblock,
	videoblock: VideoBlock,
	logos: Logos
}
