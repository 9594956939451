import {
	DPArticle,
	DPDossier,
	DPPodcast,
	DPPress,
	DPReadingRoomBlock,
	DPVideoPage
} from 'types/DPTypes'
import {
	Magazine,
	MagazineItems,
	MagazineTextualItems
} from 'blocks/readingroom/components/magazine'
import React, {useEffect, useState} from 'react'
import {SearchParams, useImecSearchParams} from 'util/useImecSearchParams'
import {
	SearchresultsEmpty,
	SearchresultsLoader,
	SearchresultsMore
} from 'layout/search/searchresults'

import {Highlighted} from 'blocks/readingroom/components/highlighted'
import {READINGROOM_SEARCH_URL} from 'util/urls'
import {ReadingroomSearch} from 'blocks/readingroom/components/readingroomsearch'
import algoliasearch from 'algoliasearch/lite'
import css from './readingroom.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useLinks} from 'util/links'
import {useTranslation} from 'util/i18'

const styles = fromModule(css)
const PAGE_AMOUNT = 9
const DOSSIER_AMOUNT = 9

export const useReadingroomSearchParams = () => {
	const result = useImecSearchParams()
	const {readingroom} = useLinks()

	const showClearBtn =
		!!result.query || !!result.tags.length || !!result.partner_related
	const isDynamic = showClearBtn || !!result.type
	const isDossiers = result.type === 'dossier'

	return {
		...result,
		isDynamic,
		showClearBtn,
		isDossiers,
		createUrlObject: (params: Partial<SearchParams>) => {
			const urlObject = result.createUrlObject(params)
			return {
				...urlObject,
				query: {
					...urlObject.query,
					slug: readingroom.url.split('/').slice(1)
				}
			}
		}
	}
}

export const Readingroom: React.FC<DPReadingRoomBlock> = ({
	categories,
	items,
	reading_dossiers,
	all_dossiers,
	has_podcasts
}) => {
	const {isDynamic, isDossiers} = useReadingroomSearchParams()

	return (
		<div className={styles.readingroom()}>
			<ReadingroomSearch categories={categories} popularTags={[]} />
			<ReadingroomTabs has_podcasts={has_podcasts} />
			{isDossiers ? (
				<ReadingroomDossiers dossiers={all_dossiers} />
			) : isDynamic ? (
				<ReadingroomDynamic />
			) : (
				<ReadingroomStatic items={items} dossiers={reading_dossiers || []} />
			)}
		</div>
	)
}

const ReadingroomTabs: React.FC<{has_podcasts: boolean}> = ({has_podcasts}) => {
	const {updateParams, type} = useReadingroomSearchParams()
	const t = useTranslation()

	const tabs = ['press', 'article'] // temp no 'video_page' type
	if (has_podcasts) tabs.push('podcast')
	if (
		['blocks', 'block', 'vlaanderen'].indexOf(
			process.env.NEXT_PUBLIC_PROJECT_KEY
		) >= 0
	)
		tabs.push('dossier')

	return (
		<div className={styles.tabs()}>
			<div className={styles.tabs.items()}>
				<div
					className={styles.tabs.items.item.is({active: !type})()}
					onClick={() => updateParams({page: 0, type: null})}
				>
					{t.readingroom.tabs.all}
				</div>
				{tabs.map((itemType) => (
					<div
						key={`tab_${itemType}`}
						className={styles.tabs.items.item.is({active: type === itemType})()}
						onClick={() => updateParams({page: 0, type: itemType})}
					>
						{t.readingroom.tabs[itemType]}
					</div>
				))}
			</div>
		</div>
	)
}

const ReadingroomDossiers: React.FC<{
	dossiers: Array<DPDossier>
}> = ({dossiers}) => {
	const {page, updateParams} = useReadingroomSearchParams()

	return (
		<>
			<Magazine>
				<>
					<MagazineItems
						items={dossiers.slice(0, (page + 1) * DOSSIER_AMOUNT)}
					/>
				</>
				{(page + 1) * DOSSIER_AMOUNT < dossiers.length && (
					<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
				)}
			</Magazine>
		</>
	)
}

const ReadingroomStatic: React.FC<{
	items: Array<DPArticle | DPPress | DPVideoPage | DPPodcast>
	dossiers: Array<DPDossier>
}> = ({items, dossiers}) => {
	const {page, setParams} = useReadingroomSearchParams()

	return (
		<>
			<Magazine>
				<MagazineItems items={items.slice(0, 3)} />
				<Highlighted dossier={dossiers[0]} mod="blue" />
				<MagazineItems items={items.slice(3, PAGE_AMOUNT)} />
				{page === 0 && items.length > PAGE_AMOUNT && (
					<SearchresultsMore onClick={() => setParams({page: 1})} />
				)}
				{page === 0 && items.length <= PAGE_AMOUNT && dossiers.length > 1 && (
					<>
						{dossiers.slice(1).map((d, i) => (
							<Highlighted dossier={d} key={'dossier' + i} />
						))}
					</>
				)}
			</Magazine>
			{page >= 1 && (
				<ReadingroomDynamic
					skip={items.slice(0, PAGE_AMOUNT)}
					dossiers={dossiers.slice(1)}
				/>
			)}
		</>
	)
}

const ReadingroomDynamic: React.FC<{
	skip?: Array<DPArticle | DPPress | DPVideoPage | DPPodcast>
	dossiers?: Array<DPDossier>
}> = ({skip, dossiers = []}) => {
	const [prevData, setPrevData] = useState(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const {page, label, tags, type, query, partner_related, updateParams} =
		useReadingroomSearchParams()

	const url = READINGROOM_SEARCH_URL({
		page,
		label,
		query,
		tags,
		partner_related,
		type,
		skip
	})
	useEffect(() => {
		if (isLoading) return
		setIsLoading(true)
		const algoliaClient = algoliasearch(
			process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
			process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
		)
		const agoliaIndex = algoliaClient.initIndex(
			process.env.NEXT_PUBLIC_ALGOLIA_INDEX + '_rr_pages'
		)
		const filters = []
		if (type) filters.push(`type:"${cleanFilter(type)}"`)
		if (label) filters.push(`label:"${cleanFilter(label)}"`)
		if (partner_related) filters.push(`partner_related:true`)
		else filters.push(`partner_related:false`)
		if (tags && tags.length) {
			for (const tag of tags) filters.push(`tags:"${cleanFilter(tag)}"`)
		}

		const notFilters = []
		if (skip && skip.length) {
			for (const skipItem of skip)
				notFilters.push(`node_id:${skipItem.node_id}`)
		}

		const filtersConcat =
			filters.length && notFilters.length
				? ' AND NOT '
				: notFilters.length
				? 'NOT '
				: ''

		let fixedPage = getFixedPage()

		let hitsPerPage = PAGE_AMOUNT
		if (fixedPage > 0 && (!prevData || !prevData.hits.length)) {
			hitsPerPage = hitsPerPage * (page + 1)
			fixedPage = 0
		}

		agoliaIndex
			.search(query ? query : '', {
				hitsPerPage: hitsPerPage,
				page: fixedPage,
				filters:
					filters.join(' AND ') + filtersConcat + notFilters.join(' AND NOT ')
			})
			.then((res) => {
				if (!prevData || fixedPage === 0) {
					setPrevData(res)
				} else {
					setPrevData({
						...res,
						hits: [...prevData.hits, ...res.hits]
					})
				}
				setIsLoading(false)
			})
	}, [url])

	const cleanFilter = (s: string) => s.replace(/(['"])/g, '\\$1')

	const getFixedPage = () => {
		if (tags && tags.length) return page
		if (type) return page
		if (label) return page
		if (partner_related) return page
		if (query && query.length) return page
		if (page && page > 0) return page - 1
		return page
	}

	if (!prevData) return <SearchresultsLoader />
	if (!prevData.hits.length && !type) return null
	if (!prevData.hits.length) return <SearchresultsEmpty />

	const items = prevData.hits.map((h) => ({
		...h,
		...JSON.parse(h.node),
		title: h._highlightResult.title.value,
		alt_title: h._highlightResult.alt_title.value,
		description: h._highlightResult.description.value,
		alt_description: h._highlightResult.alt_description.value
	}))
	const showMore = prevData.nbPages - 1 > prevData.page

	if (partner_related) {
		return (
			<>
				{dossiers.length ? (
					<>
						{dossiers
							.slice(0, Math.ceil(items.length / 6))
							.map((dossier, index) => {
								return (
									<>
										<Highlighted dossier={dossier} />
										<MagazineTextualItems
											items={items.slice(index * 6, (index + 1) * 6)}
										/>
									</>
								)
							})}
						<MagazineTextualItems items={items.slice(dossiers.length * 6)} />
					</>
				) : (
					<MagazineTextualItems items={items} />
				)}
				{isLoading && <SearchresultsLoader />}
				{showMore && !isLoading && (
					<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
				)}
			</>
		)
	}

	return (
		<Magazine>
			{dossiers.length ? (
				<>
					{dossiers
						.slice(0, Math.ceil(items.length / 6))
						.map((dossier, index) => {
							return (
								<>
									<Highlighted dossier={dossier} />
									<MagazineItems
										items={items.slice(index * 6, (index + 1) * 6)}
									/>
								</>
							)
						})}
					<MagazineItems items={items.slice(dossiers.length * 6)} />
				</>
			) : (
				<MagazineItems items={items} />
			)}
			{isLoading && <SearchresultsLoader />}
			{showMore && !isLoading && (
				<SearchresultsMore onClick={() => updateParams({page: page + 1})} />
			)}
		</Magazine>
	)
}
