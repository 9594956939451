import {Block} from 'blocks/block'
import {DPHerohomeBlock} from 'types/DPTypes'
import {HeroLink} from 'blocks/hero/hero'
import React from 'react'
import {Theme} from 'layout/theme'
import css from './herohome.module.scss'
import {fromModule} from 'util/styler/Styler'

const styles = fromModule(css)

export const HeroHome: React.FC<DPHerohomeBlock> = (data) => {
	const {_type, description, link} = data

	return (
		<Block type={_type} className={styles.herohome()}>
			<Theme.Container>
				<div className={styles.herohome.content()}>
					<div className={styles.herohome.content.description()}>
						<Theme.Wysiwyg>{description}</Theme.Wysiwyg>
					</div>
					{link.map(
						(link, i) =>
							link &&
							link.url && <HeroLink key={i} url={link.url} title={link.title} />
					)}
				</div>
			</Theme.Container>
		</Block>
	)
}
