import React, {useContext} from 'react'

import {BlockContext} from 'blocks/blocks'
import {DPPrototypeBlock} from 'types/DPTypes'
import {Paragraph} from 'blocks/paragraph'

export const Prototype: React.FC<DPPrototypeBlock> = ({blocks, id}) => {
	const currentContext = useContext(BlockContext)
	const blocksAbove = currentContext.blocks || []
	const index = blocksAbove.findIndex((bl) => bl.id === id)
	const prevBlock = blocksAbove[index - 1]

	//BlockContext.Provider used to properly replace anchor blocks
	return (
		<BlockContext.Provider
			value={{
				blocks: [
					...(prevBlock?._type === 'anchor' ? [prevBlock] : []),
					...blocks
				]
			}}
		>
			{blocks.map((block, i) => (
				<Paragraph {...block} key={i} />
			))}
		</BlockContext.Provider>
	)
}
