import {DPDownloadsBlock, DPUrl} from 'types/DPTypes'

import {Icon} from 'assets/icon'
import {Block} from 'blocks/block'
import {Theme} from 'layout/theme'
import React from 'react'
import {useTranslation} from 'util/i18'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {useIDFromAnchorBlock} from 'util/useAnchor'
import css from './downloads.module.scss'

const styles = fromModule(css)

export type DownloadsType = {
	lightmode?: boolean
	title?: string
	items: Array<DownloadsItemType>
}

export type DownloadsItemType = {
	type: 'file' | 'link'
	url?: DPUrl
	title?: string
}

export const Downloads: React.FC<DPDownloadsBlock> = (data) => {
	const {_type, lightmode, title, items: pItems} = data
	const t = useTranslation()
	const id = useIDFromAnchorBlock(data)

	const items = []

	;(pItems || []).forEach((pItem) => {
		if (pItem._type === 'downloads_file')
			items.push({
				type: 'file',
				url: pItem.file?.url,
				title: pItem.title
			})
		if (pItem._type === 'downloads_link')
			items.push({
				type: pItem.filesymbol ? 'file' : 'link',
				url: pItem.downloads_link?.url,
				title: pItem.downloads_link?.title
			})
	})

	return (
		<Block
			type={_type}
			anchorID={id}
			className={styles.downloads.mod({light: lightmode})()}
		>
			<Theme.Container>
				<Theme.H2
					className={styles.downloads.title()}
					mod={{blueline: lightmode}}
				>
					{title || t.downloads.title}
				</Theme.H2>
				<div className={styles.downloads.content()}>
					<div className={styles.downloads.content.row()}>
						{(items || []).map((item, i) => (
							<div className={styles.downloads.content.row.col()} key={i}>
								<DownloadsItem
									type={item.type}
									title={item.title}
									url={item.url}
								/>
							</div>
						))}
					</div>
				</div>
			</Theme.Container>
		</Block>
	)
}

const DownloadsItem: React.FC<DownloadsItemType> = (data) => {
	const {type, url, title} = data
	if (!url) return null

	return (
		<div className={styles.item()}>
			<Link href={url} className={styles.item.link()}>
				<div className={styles.item.link.icon()}>
					<Icon icon={type === 'file' ? 'File' : 'ArrowDown'} />
				</div>
				<p className={styles.item.link.text()}>{title}</p>
			</Link>
		</div>
	)
}
